import React from 'react';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import set from 'lodash/set';

const styles = theme => ({
  disabled: {
    color: 'black'
  },
  root: {},
  label: {
    fontSize: 15
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'calc(100% - 25px)'
    }
  },
  container: {
    position: 'relative',
    width: '100%'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  }
});

function getSuggestions(value, suggestions) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? suggestions
    : suggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          suggestion.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function renderInputComponent(inputProps) {
  return <Input fullWidth {...inputProps} />;
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </div>
    </MenuItem>
  );
}

class Field extends React.Component {
  state = {
    suggestions: []
  };
  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value, this.props.typeaheadValues)
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };
  handleOnChange = e => {
    if (this.props.notifyOnChange) {
      this.props.notifyOnChange();
    }
    this.setState(
      {
        value: e.target.value
      },
      () => {
        if (this.props.type === 'select' || this.props.type === 'typeahead') {
          this.handleOnBlur();
        }
      }
    );
  };
  handleOnBlur = () => {
    this.props.save(this.getValue(), this.props.path, this.props.label);
  };
  getValue = () => {
    return typeof this.state.value === 'undefined'
      ? this.props.value || this.props.defaultValue || ''
      : this.state.value;
  };
  getDateTimeValue = propName => {
    return this.props[propName] || '';
  };
  render() {
    const value = this.getValue();
    const inputLabel = (
      <Typography
        style={{ minWidth: this.props.minWidth || 250 }}
        className={this.props.classes.label}
        variant="subtitle2"
      >
        {this.props.label}
      </Typography>
    );
    switch (this.props.type) {
      case 'typeahead':
        const autosuggestProps = {
          renderInputComponent,
          suggestions: this.state.suggestions,
          onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
          onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
          getSuggestionValue: suggestion => suggestion,
          renderSuggestion
        };
        return (
          <div className={this.props.classes.fieldWrapper}>
            {inputLabel}
            <div style={{ width: '100%' }}>
              <Autosuggest
                shouldRenderSuggestions={() => true}
                {...autosuggestProps}
                inputProps={{
                  fullWidth: true,
                  onBlur: this.props.handleOnBlur || this.handleOnBlur,
                  placeholder: this.props.placeholder,
                  onChange: (event, { newValue, method }) => {
                    const e = {};
                    set(e, 'target.value', newValue);
                    console.log('e', e, this.props);
                    if (this.props.handleOnChange) {
                      this.props.handleOnChange(e);
                    } else {
                      this.handleOnChange(e);
                    }
                    if (this.props.getAutoFill) {
                      this.props.getAutoFill(newValue);
                    }
                  },
                  value: value,
                  disabled: this.props.disabled,
                  classes: {
                    root: this.props.classes.root
                  }
                }}
                theme={{
                  container: this.props.classes.container,
                  suggestionsContainerOpen: this.props.classes
                    .suggestionsContainerOpen,
                  suggestionsList: this.props.classes.suggestionsList,
                  suggestion: this.props.classes.suggestion
                }}
                renderSuggestionsContainer={options => (
                  <Paper {...options.containerProps} square>
                    {options.children}
                  </Paper>
                )}
              />
              {this.props.error ? (
                <FormHelperText>{this.props.error}</FormHelperText>
              ) : this.props.hideHelperWhenDirty && value ? null : this.props
                  .helperText ? (
                <FormHelperText>{this.props.helperText}</FormHelperText>
              ) : null}
            </div>
          </div>
        );
      case 'text':
        return (
          <FormControl
            className={this.props.classes.fieldWrapper}
            error={!!this.props.error}
            required={this.props.required || false}
            fullWidth
            margin="normal"
          >
            {inputLabel}
            <div style={{ width: '100%' }}>
              <Input
                type={this.props.inputType || 'text'}
                fullWidth
                onBlur={this.props.handleOnBlur || this.handleOnBlur}
                placeholder={this.props.placeholder}
                onChange={this.props.handleOnChange || this.handleOnChange}
                value={value}
                disabled={this.props.disabled}
                classes={{
                  root: this.props.classes.root
                }}
              />
              {this.props.error ? (
                <FormHelperText>{this.props.error}</FormHelperText>
              ) : this.props.helperText ? (
                <FormHelperText>
                  {this.props.hideHelperWhenDirty && value
                    ? ''
                    : this.props.helperText}
                </FormHelperText>
              ) : null}
            </div>
          </FormControl>
        );
      case 'textarea':
        return (
          <FormControl
            className={this.props.classes.fieldWrapper}
            error={!!this.props.error}
            required={this.props.required || false}
            fullWidth
            margin="normal"
          >
            {inputLabel}
            <div style={{ width: '100%' }}>
              <Input
                type={this.props.inputType || 'text'}
                fullWidth
                onBlur={this.props.handleOnBlur || this.handleOnBlur}
                placeholder={this.props.placeholder}
                onChange={this.props.handleOnChange || this.handleOnChange}
                value={value}
                rowsMax={20}
                multiline
                disabled={this.props.disabled}
                classes={{
                  root: this.props.classes.root
                }}
              />
              {this.props.error ? (
                <FormHelperText style={{ color: 'red' }}>
                  {this.props.error}
                </FormHelperText>
              ) : this.props.helperText ? (
                <FormHelperText>{this.props.helperText}</FormHelperText>
              ) : null}
            </div>
          </FormControl>
        );
      case 'select':
        return (
          <div className={this.props.classes.fieldWrapper}>
            {inputLabel}
            <Select
              fullWidth
              displayEmpty={this.props.placeholder ? true : false}
              classes={{
                root: this.props.classes.root,
                disabled: this.props.classes.disabled
              }}
              disabled={this.props.disabled}
              onChange={this.props.handleOnChange || this.handleOnChange}
              value={value}
            >
              {this.props.placeholder ? (
                <MenuItem value="" disabled>
                  <span style={{ color: '#A2A2A2' }}>
                    {this.props.placeholder}
                  </span>
                </MenuItem>
              ) : null}
              {this.props.options.map(o => (
                <MenuItem value={o}>{o}</MenuItem>
              ))}
            </Select>
          </div>
        );
      case 'static-text':
        return (
          <div className={this.props.classes.fieldWrapper}>
            {inputLabel}
            <div style={{ width: '100%' }}>
              <div className={this.props.classes.fieldWrapper}>
                <div
                  className={
                    this.props.defaultValue === value
                      ? 'static-placeholder-text'
                      : 'static-form-text'
                  }
                  style={{ marginRight: 10 }}
                >
                  {((value || '') + '').split('\n').map((i, key) => {
                    return <div key={key}>{i}</div>;
                  })}
                </div>
                <div>{this.props.oldValue}</div>
              </div>
              {this.props.error ? (
                <FormHelperText>{this.props.error}</FormHelperText>
              ) : this.props.helperText ? (
                <FormHelperText>{this.props.helperText}</FormHelperText>
              ) : null}
            </div>
          </div>
        );

      case 'switch':
        return (
          <div className={this.props.classes.fieldWrapper}>
            {inputLabel}
            <FormControlLabel
              control={
                <Switch
                  checked={this.props.value}
                  onChange={this.props.handleOnChange || this.handleOnChange}
                  value="notused"
                  color="primary"
                />
              }
              label={this.props.switchLabel}
            />
          </div>
        );
      case 'checkbox':
        return (
          <div className={this.props.classes.fieldWrapper}>
            {inputLabel}
            <FormControlLabel
              control={
                <Checkbox
                  checked
                  onChange={() => 'checkedB'}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Primary"
            />
          </div>
        );
      case 'datetime':
        return (
          <div className={this.props.classes.fieldWrapper}>
            {inputLabel}
            <div
              style={{
                paddingTop: 5,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <TextField
                label="Date"
                type="date"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={e => {
                  this.props.setValue(e.target.value, 'Date');
                }}
                onFocus={() => {
                  if (
                    !this.getDateTimeValue('dateValue') &&
                    this.props.defaultValue
                  ) {
                    this.props.setValue(this.props.defaultValue, 'Date');
                  }
                }}
                value={this.getDateTimeValue('dateValue')}
              />
              <TextField
                onChange={e => {
                  console.log('e.target.value', e.target.value);

                  this.props.setValue(e.target.value, 'Time');
                }}
                onFocus={() => {
                  if (!this.getDateTimeValue('timeValue')) {
                    var d = new Date(),
                      h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
                      m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
                    this.props.setValue(h + ':' + m, 'Time');
                  }
                }}
                value={this.getDateTimeValue('timeValue')}
                label="Time"
                type="time"
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  }
}

Field.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Field);
