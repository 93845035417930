import React, { Component } from 'react';
import Layout from '../layout/Layout';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { loadColumns } from './columns';
import AppTable from '../shared/table/AppTable';
import TopToolbar from './TopToolbar';
import LoadTabs from './LoadTabs';
import {
  getPaginatedLoadsApiCall,
  rejectTendersApiCall,
  acceptTendersApiCall,
  archiveLoadsApiCall
} from './api';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  main: {},
  root: {
    width: '100%'
  },
  toolbarStyles: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  }
});

class LoadsContainer extends Component {
  state = {
    loading: false,
    page: 0,
    rowsPerPage: 10,
    order: 'desc',
    orderBy: 'lastUpdated',
    selected: [],
    data: [],
    searchTerm: ''
  };
  componentDidMount() {
    this.mounted = true;
    //this.setSearchTerm = debounce(this.setSearchTerm, 200);
    this.searchLoads = debounce(this.searchLoads, 600);
    this.fetchData();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setSearchTerm = searchTerm => {
    this.setState(
      {
        searchTerm
      },
      () => {
        this.searchLoads();
      }
    );
  };
  searchLoads = () => {
    this.fetchData();
  };
  getParams = () => {
    const params = {
      skip: this.state.page * this.state.rowsPerPage,
      sortField: this.state.orderBy,
      sortOrder: this.state.order,
      limit: this.state.rowsPerPage,
      searchTerm: this.state.searchTerm,
      activeTab: window._.get(
        this,
        'props.match.params.activeTab',
        'Tendered Loads'
      )
    };
    return params;
  };
  fetchData = () => {
    window.scrollTo(0, 0);

    this.setState(
      {
        loading: true
      },
      async () => {
        const result = await getPaginatedLoadsApiCall(this.getParams());
        console.log('TCL: Dashboard -> fetchData -> result', result);
        this.setState({
          loading: false,
          data: result.rows,
          totalRows: result.totalRows
        });
      }
    );
  };

  rejectTenders = reason => {
    this.setState({}, async () => {
      await rejectTendersApiCall(this.state.selected, reason);
      this.handleTabChange('Rejected Tenders');
    });
  };

  acceptTenders = reason => {
    this.setState({}, async () => {
      await acceptTendersApiCall(this.state.selected);
      this.handleTabChange('Active Loads');
    });
  };

  archiveLoads = archive => {
    this.setState({}, async () => {
      await archiveLoadsApiCall(this.state.selected, archive);
      if (archive) {
        this.handleTabChange('Archived Loads');
      } else {
        this.deselectAll();
        this.fetchData();
      }
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page }, () => this.fetchData());
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value }, () => this.fetchData());
  };
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy }, () => this.fetchData());
  };
  setIsSelectMode = selectMode => {
    this.setState({
      isSelectMode: selectMode ? true : false
    });
  };
  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({
        selected: state.data.map(n => {
          return n.id;
        }),
        data: this.state.data
      }));
      return;
    }
    this.setState({ selected: [] });
  };
  selectRow = (event, id) => {
    if (event.target.checked) {
      if (!this.state.selected.includes(id)) {
        this.setState({
          isSelectMode: true,
          selected: [...this.state.selected, id]
        });
      }
    } else {
      this.setState({
        isSelectMode: this.state.selected.length === 1 ? false : true,
        selected: this.state.selected.filter(s => s !== id)
      });
    }
  };
  handleClick = (row, tabName = 'Status, Customer, Ref No.') => {
    if (typeof row === 'string') {
      row = find(this.state.data, ['id', row]);
    }
    window.backButtonUrl = this.props.match.url;
    this.props.history.push(
      `/load/${row.id}/${row.customerReferenceNumber}/${tabName}`
    );
  };
  handleTabChange = tab => {
    this.props.history.push(`/loads/${tab}`);
  };
  deselectAll = () => {
    this.setState({
      selected: []
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      window._.get(this, 'props.match.params.activeTab', '') !==
      window._.get(prevProps, 'match.params.activeTab', '')
    ) {
      this.setState(
        {
          selected: [],
          page: 0
        },
        () => {
          this.fetchData();
        }
      );
    }
  }
  render() {
    const { classes, match } = this.props;
    const { loading, data, order, orderBy } = this.state;

    const { activeTab } = match.params;

    return (
      <Layout
        searchProps={{
          placeholder: `Search ${activeTab}...`,
          value: this.state.searchTerm,
          onChange: this.setSearchTerm,
          onRequestSearch: this.searchLoads
        }}
        title="Loads"
      >
        <Paper className={classes.root}>
          <Hidden xsDown>
            <LoadTabs
              handleTabChange={this.handleTabChange}
              activeTab={activeTab}
            />
          </Hidden>

          <TopToolbar
            goToLoad={this.handleClick}
            activeTab={activeTab}
            setIsSelectMode={this.setIsSelectMode}
            isSelectMode={this.state.isSelectMode}
            deselectAll={this.deselectAll}
            archiveLoads={this.archiveLoads}
            rejectTenders={this.rejectTenders}
            acceptTenders={this.acceptTenders}
            selected={this.state.selected}
            numSelected={this.state.selected.length}
          />

          {!loading && (!data || !data.length) ? (
            <Toolbar className={classes.toolbarStyles}>
              <Typography variant="subtitle1">
                {`No ${activeTab} found. ${
                  this.state.searchTerm
                    ? 'Try changing the search criteria.'
                    : ''
                }`}
              </Typography>
            </Toolbar>
          ) : (
            <AppTable
              activeTab={activeTab}
              isSelectMode={this.state.isSelectMode}
              handleClick={this.handleClick}
              totalRows={this.state.totalRows}
              selected={this.state.selected}
              handleSelectAllClick={this.handleSelectAllClick}
              selectRow={this.selectRow}
              handleRequestSort={this.handleRequestSort}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              rows={data}
              columns={loadColumns}
              order={order}
              orderBy={orderBy}
              loading={loading}
            />
          )}
        </Paper>
      </Layout>
    );
  }
}

export default withStyles(styles)(withRouter(LoadsContainer));
