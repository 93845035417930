import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const AlertDialog = props => {
  const { open, handleClose, declineInvite, acceptInvite } = props;
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Accept invite to link?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {window._.get(
            props,
            'inviteDetails.customerName',
            'Unnamed customer'
          )}{' '}
          has invited you to link with them via AscendPortal. By accepting this
          invitation, you will be able to electronically receive load tenders,
          accept/reject them, set pickup and delivery appointment times, and
          automatically share load updates with{' '}
          {window._.get(
            props,
            'inviteDetails.customerName',
            'Unnamed customer'
          )}
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={declineInvite} color="primary">
          Decline Invite
        </Button>
        <Button onClick={acceptInvite} color="primary" autoFocus>
          Accept Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
