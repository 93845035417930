import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  }
}));

export default function FinancialsTable({ data, totalAmount, isOld }) {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell align="right">Quantity</TableCell>
          <TableCell align="right">Rate</TableCell>
          <TableCell align="right">Total</TableCell>
          <TableCell>Notes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ textDecoration: isOld ? 'line-through' : 'none' }}>
        {data.map((row, i) => (
          <TableRow key={i}>
            <TableCell>{row.type}</TableCell>
            <TableCell align="right">{row.quantity}</TableCell>
            <TableCell align="right">{row.rate}</TableCell>
            <TableCell align="right">{`$${(+(row.total || 0))
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</TableCell>
            <TableCell>
              {row.external_desc && row.desc ? row.desc : null}
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell align="right">
            <strong>Pay Item(s) Total:</strong>
          </TableCell>
          <TableCell align="right">
            {`$${(+(totalAmount || 0))
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
          </TableCell>
          <TableCell />
        </TableRow>
      </TableBody>
    </Table>
  );
}
