import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,0.8)'
  },
  overlayContent: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#555'
  }
});

const LoadingOverlay = ({ classes }) => (
  <div className={classes.overlay}>
    <div className={classes.overlayContent}>
      <CircularProgress disableShrink />
    </div>
  </div>
);

export default withStyles(styles)(LoadingOverlay);
