import React, { Component } from 'react';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { stopColumns } from './columns';
import Hidden from '@material-ui/core/Hidden';
import EditStopsList from './EditStopsList';
import StopDialog from './StopDialog';
import { FIELDS_WITH_OLD_VALUES } from '../utility';

const TableBodySortable = SortableContainer(
  ({ children, displayRowCheckbox }) => (
    <TableBody displayRowCheckbox={displayRowCheckbox}>{children}</TableBody>
  )
);

TableBodySortable.muiName = 'TableBody';

const getValue = (c, r, actions, load) => {
  return c.render
    ? c.render(window._.get(r, c.key, ''), r, actions, load)
    : window._.get(r, c.key, '');
};

const getOldValue = (c, r, actions, load) => {
  if (
    !FIELDS_WITH_OLD_VALUES.includes(c.key) ||
    !r.oldStop ||
    (!r.oldStop.isChanged && !r.oldStop.isNew)
  ) {
    return null;
  }
  if (r.oldStop.isNew) {
    if (c.key === 'actions') {
      return (
        <strong style={{ color: 'red' }}>
          This stop is newly added by your customer.
        </strong>
      );
    }
  } else {
    var oldValue = getValue(c, r.oldStop, actions, load);
    return (
      <div>
        <div>
          {c.key === 'actions' ? (
            <strong style={{ color: 'red' }}>
              This stop was updated by your customer.
            </strong>
          ) : null}
        </div>
        <span style={{ color: '#a2a2a2' }}>Old value was: </span>
        <span style={{ textDecoration: 'line-through' }}>
          {oldValue || <em>Not Set</em>}
        </span>
      </div>
    );
  }
  return null;
};

const Row = SortableElement(({ r, handleClick, actions, load }) => {
  return (
    <TableRow className="clickable">
      {stopColumns.map(
        c => (
          <TableCell
            onClick={() => handleClick(r)}
            className={c.className || ''}
            key={c.key}
            padding={c.disablePadding ? 'none' : 'default'}
            align={c.align}
          >
            {getValue(c, r, actions, load)}
            {getOldValue(c, r, actions, load)}
          </TableCell>
        ),
        this
      )}
    </TableRow>
  );
});

const LegRow = SortableElement(({ r }) => {
  return (
    <TableRow key={`${r.legId}`}>
      <TableCell colSpan="9">
        {r.miles ? (
          <span>
            {` Distance: `}
            <strong>{`${r.miles.toLocaleString(undefined, {
              maximumFractionDigits: 0
            })} miles`}</strong>
          </span>
        ) : (
          'Could not calculate miles'
        )}
      </TableCell>
    </TableRow>
  );
});

class EditStopsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSorting: false
    };
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { load } = this.props;
    const newStopsWithLegs = arrayMove(
      window._.get(load, 'stopsWithLegs', []),
      oldIndex,
      newIndex
    );
    const stopOrder = newStopsWithLegs.filter(s => !s.isLeg).map(s => s.stopId);
    this.props.handleStopReorderApiCall(stopOrder);
    this.setState({
      isSorting: false
    });
  };
  onSortStart = ({ oldIndex, newIndex }) => {
    if (this.props.showStatusWarning('REORDER')) {
      return false;
    }
    this.setState({
      isSorting: true
    });
  };
  handleStopClick = row => {
    if (this.props.showStatusWarning('SCHEDULE')) {
      return false;
    }
    this.setState({
      activeStop: row
    });
  };
  closeDialog = () => {
    this.setState({
      activeStop: null
    });
  };
  renderDialog = fullScreen => {
    return (
      <StopDialog
        actions={this.props.load.carrierPortalFields.actions || {}}
        save={this.props.editStopApiCall}
        fullScreen={fullScreen}
        stop={this.state.activeStop}
        dialogOpen={this.state.activeStop ? true : false}
        closeDialog={this.closeDialog}
      />
    );
  };
  render() {
    const { load, classes } = this.props;
    if (!load) {
      return null;
    }
    return (
      <React.Fragment>
        <Hidden smUp>
          {this.renderDialog(true)}
          <EditStopsList
            load={load}
            actions={this.props.load.carrierPortalFields.actions || {}}
            onSortStart={this.onSortStart}
            onSortEnd={this.onSortEnd}
            isSorting={this.state.isSorting}
            columns={stopColumns}
            handleClick={this.handleStopClick}
            rows={window._.get(load, 'stopsWithLegs', [])}
          />
        </Hidden>
        <Hidden xsDown>
          {this.renderDialog(false)}
          <Table>
            <TableHead displaySelectAll={false} adjustForCheckbox={false}>
              <TableRow>
                {stopColumns.map(
                  c => (
                    <TableCell
                      className={c.className || ''}
                      key={c.key}
                      padding={c.disablePadding ? 'none' : 'default'}
                      align={c.align}
                    >
                      {c.title}
                    </TableCell>
                  ),
                  this
                )}
              </TableRow>
            </TableHead>
            <TableBodySortable
              updateBeforeSortStart={this.onSortStart}
              onSortEnd={this.onSortEnd}
              useDragHandle
              displayRowCheckbox={false}
            >
              {window._.get(load, 'stopsWithLegs', []).map((r, i) => {
                if (r.isLeg) {
                  if (this.state.isSorting) {
                    return null;
                  }
                  return <LegRow disabled index={i} key={r.legId} r={r} />;
                }
                return (
                  <Row
                    load={load}
                    actions={this.props.load.carrierPortalFields.actions || {}}
                    r={r}
                    index={i}
                    handleClick={this.handleStopClick}
                    key={r.stopId}
                  />
                );
              })}
              {load.calculatedMiles ? (
                <TableRow>
                  <TableCell colSpan="9">
                    {` Total Calculated Distance: `}&nbsp;
                    <strong>{`${load.calculatedMiles.toLocaleString(undefined, {
                      maximumFractionDigits: 0
                    })} miles`}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
              {load.loadTenderMiles ? (
                <TableRow>
                  <TableCell colSpan="9">
                    {` Original Contract Distance: `}
                    <strong>{`${(+load.loadTenderMiles).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 0
                      }
                    )} miles`}</strong>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBodySortable>
          </Table>
        </Hidden>
      </React.Fragment>
    );
  }
}

export default EditStopsTab;
