import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { formatDate } from '../utility';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import EditIcon from '@material-ui/icons/Edit';
import get from 'lodash/get';
import some from 'lodash/some';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
});

const ListSortable = SortableContainer(({ children, className }) => (
  <List className={className}>{children}</List>
));

ListSortable.muiName = 'List';

const renderReference = (i, a, row, load) => {
  if (load && load.orders && load.orders.length) {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>PO Number</th>
            <th>Pallets</th>
            <th>Cases</th>
            <th>Weight</th>
            <th>Cubes</th>
          </tr>
        </thead>
        <tbody>
          {(a.reference || '').split(/\n/).map(function(o) {
            if (o) {
              var myOrder;
              some(load.orders, function(so) {
                if (so.po_num === o) {
                  myOrder = so;
                  return true;
                }
              });
              if (!myOrder) {
                return null;
              }
              return (
                <tr>
                  <td>{o}</td>
                  <td>{myOrder.pallets}</td>
                  <td>{myOrder.cases}</td>
                  <td>{myOrder.weight}</td>
                  <td>{myOrder.order_cubes}</td>
                </tr>
              );
            } else {
              return '';
            }
          })}
        </tbody>
      </table>
    );
  }
  return a.reference ? (
    <div>
      <strong>
        {row.actions.length > 1 ? `Action #${i + 1} - ` : ''}
        Reference
        <br />
      </strong>
      {a.reference}
    </div>
  ) : null;
};

const Item = SortableElement(
  ({ handleClick, row, isSorting, actions, isOld, load }) => {
    return (
      <ListItem
        style={{
          width: '100%'
        }}
        divider
        key={row.stopId}
        role={undefined}
        button={isOld ? false : true}
        onClick={() => (isOld ? () => {} : handleClick(row))}
      >
        <div style={{ width: '100%' }}>
          {row.oldStop && (row.oldStop.isNew || row.oldStop.isChanged) ? (
            <ListItemText
              primary={
                row.oldStop.isNew
                  ? `This stop is newly added by your customer.`
                  : 'This stop was updated by your customer. The old stop is shown beneath this one for comparison.'
              }
              primaryTypographyProps={{
                variant: 'subtitle1',
                style: {
                  color: 'red',
                  fontSize: '.9em',
                  fontWeight: 'bold'
                }
              }}
            />
          ) : null}
          {isOld ? (
            <ListItemText
              primary={`The old value was:`}
              primaryTypographyProps={{
                variant: 'subtitle1',
                style: {
                  color: 'red',
                  fontSize: '.9em',
                  fontWeight: 'bold'
                }
              }}
            />
          ) : null}

          <ListItemText
            primary={`Stop #${row.order} Location`}
            primaryTypographyProps={{
              variant: 'subtitle1',
              style: {
                color: 'black',
                fontSize: '.9em',
                fontWeight: 'bold',
                textDecoration: isOld ? 'line-through' : 'none'
              }
            }}
          />
          <ListItemText
            style={{
              width: '100%',
              paddingLeft: 0
            }}
            primary={`${row.name}`}
            primaryTypographyProps={{
              variant: 'subtitle1',
              style: {
                color: 'black',
                fontSize: '.9em',
                textDecoration: isOld ? 'line-through' : 'none'
              }
            }}
          />
          <ListItemText
            style={{
              width: '100%',
              paddingLeft: 0,
              whiteSpace: 'pre-wrap'
            }}
            primary={`${row.block_address || 'Address Not Entered'}`}
            primaryTypographyProps={{
              variant: 'subtitle1',
              style: {
                color: 'black',
                fontSize: '.9em',
                textDecoration: isOld ? 'line-through' : 'none'
              }
            }}
          />
          <ListItemText
            style={{
              width: '100%',
              paddingLeft: 0,
              whiteSpace: 'pre-wrap',
              textDecoration: isOld ? 'line-through' : 'none'
            }}
            primary={
              <div>
                {(row.actions || []).map((a, i) => (
                  <div key={a.id}>
                    <strong>{`${
                      row.actions.length > 1 ? `Action #${i + 1} - ` : ''
                    }${a.type}`}</strong>
                    <div>
                      <strong>
                        Originally Contracted Date/Time
                        <br />
                      </strong>
                      {`${a.date || 'Not Entered'}${
                        a.dateEnd ? ' - ' + a.dateEnd : ''
                      }`}
                    </div>

                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'left'
                        }}
                      >
                        <div>
                          <strong>Scheduled Window Date/Time </strong>
                        </div>
                        <div>
                          <EditIcon
                            style={{ paddingLeft: 5, marginBottom: -3 }}
                            fontSize="small"
                            color="primary"
                          />
                        </div>
                      </div>
                      {!actions || !Object.keys(actions).length ? (
                        'Press here to re-schedule stop'
                      ) : (
                        <React.Fragment>
                          <div style={{ whiteSpace: 'nowrap' }}>
                            {`${formatDate(
                              get(actions, `${a.id}.scheduledWindowBeginDate`)
                            )} ${get(
                              actions,
                              `${a.id}.scheduledWindowBeginTime`
                            ) || ''}${
                              get(actions, `${a.id}.scheduledWindowEndDate`)
                                ? ' - '
                                : ''
                            }`}
                          </div>
                          {get(actions, `${a.id}.scheduledWindowEndDate`) ? (
                            <div>
                              {`${formatDate(
                                get(actions, `${a.id}.scheduledWindowEndDate`)
                              )} ${get(
                                actions,
                                `${a.id}.scheduledWindowEndTime`
                              ) || ''}`}
                            </div>
                          ) : null}
                        </React.Fragment>
                      )}
                    </div>
                    {a.driverInstructions ? (
                      <div>
                        <strong>
                          {row.actions.length > 1 ? `Action #${i + 1} - ` : ''}
                          Driver Instructions
                          <br />
                        </strong>
                        {a.driverInstructions}
                      </div>
                    ) : null}
                    {a.cargo ? (
                      <div>
                        <strong>
                          {row.actions.length > 1 ? `Action #${i + 1} - ` : ''}
                          Cargo
                          <br />
                        </strong>
                        {a.cargo}
                      </div>
                    ) : null}
                    {renderReference(i, a, row, load)}
                  </div>
                ))}
              </div>
            }
            primaryTypographyProps={{
              variant: 'subtitle1',
              style: { color: 'black', fontSize: '.9em' }
            }}
          />
        </div>
      </ListItem>
    );
  }
);

class EditStopsList extends React.Component {
  state = {};
  render() {
    const { classes, rows } = this.props;

    return (
      <ListSortable
        pressDelay={200}
        updateBeforeSortStart={this.props.onSortStart}
        onSortEnd={this.props.onSortEnd}
        className={classes.root}
      >
        {rows.map((row, i) => {
          if (row.isLeg) {
            if (this.props.isSorting) {
              return null;
            }
            return (
              <ListItem
                style={{ width: '100%' }}
                divider
                disabled
                key={row.legId}
                role={undefined}
                button
                //onClick={this.props.handleClick(row)}
              >
                <ListItemText
                  primary={
                    row.miles ? (
                      <span>
                        {` Leg Distance: `}
                        <strong>{`${row.miles.toLocaleString(undefined, {
                          maximumFractionDigits: 0
                        })} miles`}</strong>
                      </span>
                    ) : (
                      'Could not calculate miles'
                    )
                  }
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    style: {
                      color: 'black',
                      fontSize: '.85em',
                      fontWeight: 'bold'
                    }
                  }}
                />
              </ListItem>
            );
          }
          return (
            <React.Fragment key={row.stopId}>
              <Item
                load={this.props.load}
                actions={this.props.actions}
                handleClick={this.props.handleClick}
                index={i}
                key={row.stopId}
                isSorting={false}
                row={row}
              />
              {row.oldStop && row.oldStop.isChanged ? (
                <Item
                  load={this.props.load}
                  isOld
                  actions={this.props.actions}
                  handleClick={this.props.handleClick}
                  index={i}
                  key={row.stopId + 'old'}
                  isSorting={false}
                  row={{ ...row.oldStop, order: row.order }}
                />
              ) : null}
            </React.Fragment>
          );
        })}
      </ListSortable>
    );
  }
}

EditStopsList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditStopsList);
