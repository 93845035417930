import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter } from 'react-router-dom';
import LogoTypography from '../shared/LogoTypography';
import AsyncButton from '../shared/AsyncButton';
import { resetPasswordApiCall } from './api';
import FooterLinks from './FooterLinks';
import queryString from 'query-string';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1) *
      3}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  forgotPassword: {
    textAlign: 'right'
  }
});

class ResetPassword extends Component {
  state = {
    loading: false,
    password: '',
    passwordConfirm: '',
    errors: {}
  };
  componentDidMount() {
    this.mounted = true;
    const { valtoken } = queryString.parse(this.props.location.search);
    if (!valtoken) {
      this.setState(
        {
          snackbarIsOpen: true
        },
        () => {
          //
        }
      );
    }
    this.valtoken = valtoken;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleResetPassword = event => {
    event.preventDefault();

    const { password, passwordConfirm } = this.state;
    if (password !== passwordConfirm) {
      this.setState({
        errors: {
          password: 'Your passwords do not match.',
          passwordConfirm: 'Your passwords do not match.'
        }
      });
      return;
    }
    this.setState(
      {
        loading: true,
        errors: {}
      },
      async () => {
        try {
          const result = await resetPasswordApiCall(password, this.valtoken);
          console.log('handleResetPassword result', result);
          if (!this.mounted) {
            return;
          }
          this.setState({
            loading: false
          });
          if (!result.error) {
            // success!
            this.props.history.push('/');
          } else {
            switch (result.error.errorCode) {
              case 'cancelled':
                return; // another request was started before this one finished
              case 1:
                this.setState({
                  snackbarIsOpen: true
                });
                return;
            }
          }
        } catch (err) {
          // this should not happen
          console.log(err);
          this.setState({
            loading: false,
            snackbarIsOpen: true
          });
        }
      }
    );
  };
  handleFieldChange = name => event => {
    this.state.errors[name] = null;
    this.setState({
      [name]: event.target.value
    });
  };
  closeSnackbar = () => {
    this.setState(
      {
        snackbarIsOpen: false
      },
      () => {
        this.props.history.push('/login');
      }
    );
  };
  renderActionButton = () => {
    return (
      <Button onClick={this.closeSnackbar} color="secondary" size="small">
        OK
      </Button>
    );
  };
  render() {
    const { classes } = this.props;
    const { loading, snackbarIsOpen } = this.state;
    return (
      <main className={classes.main}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackbarIsOpen}
          action={this.renderActionButton()}
          message={`Password recovery link has expired.`}
        />
        <Paper className={classes.paper}>
          <LogoTypography component="h1" variant="h5" />
          <br />
          <Typography variant="subtitle1">
            Enter a new password for your account.
          </Typography>
          <form className={classes.form} onSubmit={this.handleResetPassword}>
            <FormControl
              error={!!this.state.errors.password}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                value={this.state.password}
                onChange={this.handleFieldChange('password')}
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {this.state.errors.password ? (
                <FormHelperText>{this.state.errors.password}</FormHelperText>
              ) : (
                <FormHelperText>
                  We do not enforce any password rules, but try to use a strong
                  password that you will remember.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              error={!!this.state.errors.passwordConfirm}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Confirm Password</InputLabel>
              <Input
                value={this.state.passwordConfirm}
                onChange={this.handleFieldChange('passwordConfirm')}
                name="passwordConfirm"
                type="password"
                id="passwordConfirm"
                autoComplete="current-password"
              />
              {this.state.errors.passwordConfirm ? (
                <FormHelperText>
                  {this.state.errors.passwordConfirm}
                </FormHelperText>
              ) : (
                <FormHelperText>
                  Enter the same password again to ensure you did not type it in
                  incorrectly.
                </FormHelperText>
              )}
            </FormControl>
            <AsyncButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={loading}
            >
              {loading ? 'Launching' : 'Launch AscendPortal'}
            </AsyncButton>
          </form>
        </Paper>
        <FooterLinks />
      </main>
    );
  }
}

export default withStyles(styles)(withRouter(ResetPassword));
