import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { getPlurality } from '../utility';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Hidden from '@material-ui/core/Hidden';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//expand_more

const toolbarStyles = theme => ({
  root: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    paddingLeft: 30,
    color: theme.palette.primary.main,
    backgroundColor: 'white'
  }
});

const TopToolbar = React.memo(props => {
  const { numSelected, classes, goToLoad, selected, activeTab } = props;
  if (!numSelected) {
    return null;
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [rejectValue, handleRejectChange] = React.useState('');
  function handleReject() {
    setDialogOpen(true);
  }
  function handleAccept() {
    props.acceptTenders();
    handleClose();
  }
  function handleArchive() {
    props.archiveLoads(true);
    handleClose();
  }
  function handleUnArchive() {
    props.archiveLoads(false);
    handleClose();
  }
  function handleRejectSave() {
    props.rejectTenders(rejectValue);
    handleDialogClose();
    handleClose();
  }

  function handleDialogClose() {
    handleRejectChange('');
    setDialogOpen(false);
  }
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleCancel() {
    props.setIsSelectMode(false);
    props.deselectAll();
  }

  function renderDialog(fullScreen) {
    return (
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reason for rejection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please explain to the customer why you are rejecting these tender(s)
          </DialogContentText>
          <Input
            onChange={e => handleRejectChange(e.target.value)}
            value={rejectValue}
            rowsMax={10}
            multiline
            autoFocus
            fullWidth
          />
        </DialogContent>
        <DialogActions style={{ paddingBottom: fullScreen ? 25 : 10 }}>
          <Button onClick={handleDialogClose} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleRejectSave}
            variant="contained"
            color="primary"
          >
            {`Reject Tender${plurality}`}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const plurality = getPlurality(numSelected);

  const loadActions = [
    {
      label: `Accept Tender${plurality}`,
      handleClick: handleAccept,
      onlyShowOn: ['Tendered Loads']
    },
    {
      label: `Reject Tender${plurality}`,
      handleClick: handleReject,
      onlyShowOn: ['Tendered Loads']
    },
    {
      label: `Schedule Appointments`,
      handleClick: () => {
        goToLoad(selected[0], `View & Schedule Stops`);
      },
      singleOnly: true,
      onlyShowOn: ['Active Loads']
    },
    {
      label: `Update Your Truck Status`,
      singleOnly: true,
      handleClick: () => {
        goToLoad(selected[0], `Update Truck Status`);
      },
      onlyShowOn: ['Active Loads']
    },
    {
      label: `Propose Financial Changes / Send Message`,
      singleOnly: true,
      handleClick: () => {
        goToLoad(selected[0], `New Message`);
      },
      onlyShowOn: ['Tendered Loads', 'Active Loads']
    },
    {
      label: `Upload/View Docs`,
      singleOnly: true,
      handleClick: () => {
        goToLoad(selected[0], `Upload Doc`);
      }
    },
    {
      label: `Archive Load${plurality}`,
      handleClick: handleArchive,
      onlyShowOn: ['Rejected Tenders', 'Active Loads', 'Cancelled & Expired']
    },
    {
      label: `Unarchive Load${plurality}`,
      handleClick: handleUnArchive,
      onlyShowOn: ['Archived Loads']
    }
  ];

  return (
    <AppBar>
      <Hidden smUp>{renderDialog(true)}</Hidden>
      <Hidden xsDown>{renderDialog(false)}</Hidden>
      <div className={classes.root}>
        {numSelected > 0 ? (
          <React.Fragment>
            <Button
              color="secondary"
              variant="contained"
              aria-owns={anchorEl ? 'simple-menu' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              {`Load Actions`} <ExpandMoreIcon />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                disabled
              >{`${numSelected} load${plurality} selected`}</MenuItem>
              {loadActions.map((a, i) => {
                if (a.singleOnly && numSelected > 1) {
                  return null;
                }
                if (a.onlyShowOn && !a.onlyShowOn.includes(activeTab)) {
                  return null;
                }
                return (
                  <MenuItem key={i} onClick={a.handleClick}>
                    {a.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </React.Fragment>
        ) : null}
        {!numSelected && !props.isSelectMode ? (
          <Hidden smUp>
            <Button
              color="primary"
              variant="contained"
              onClick={props.setIsSelectMode}
            >
              Select Load(s)
            </Button>
          </Hidden>
        ) : null}
        {props.isSelectMode && !numSelected ? (
          <Button variant="ghost" disabled>
            Tap load(s) to select
          </Button>
        ) : null}

        {props.isSelectMode ? (
          <Button
            style={{ marginLeft: 5 }}
            variant="contained"
            onClick={handleCancel}
          >
            Deselect All
          </Button>
        ) : null}
      </div>
    </AppBar>
  );
});

TopToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

export default withStyles(toolbarStyles)(TopToolbar);
