import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Field from '../shared/Field';

const FIELDS_WITH_OLD_VALUES = [
  'truckType',
  'truckLength',
  'weight',
  'commodity',
  'temperature',
  'notes',
  'load_size',
  'stops',
  'legs'
];

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
    margin: 'auto'
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0,0,0,.03)',
    borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 20,
    '&$expanded': {
      minHeight: 20
    },
    paddingLeft: 18
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(props => <MuiExpansionPanelSummary {...props} />);

ExpansionPanelSummary.muiName = 'ExpansionPanelSummary';

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiExpansionPanelDetails);

class LoadTabContent extends Component {
  getValue = f => {
    return f.render
      ? f.render(window._.get(this.props.data, f.path, ''), this.props.data)
      : window._.get(this.props.data, f.path, '');
  };
  getOldValue = f => {
    if (
      FIELDS_WITH_OLD_VALUES.includes(f.path) &&
      window._.get(this, 'props.data.carrierPortalFields.oldValues')
    ) {
      const oldValue = window._.get(
        this,
        `props.data.carrierPortalFields.oldValues.${f.path}`,
        ''
      );
      const currentValue = window._.get(this.props.data, f.path, '');
      if (oldValue !== currentValue) {
        return (
          <span>
            <span style={{ color: 'red' }}>Old value was: </span>
            <span style={{ textDecoration: 'line-through' }}>
              {oldValue || <em>Not Set</em>}
            </span>
          </span>
        );
      }
    }
    return null;
  };
  render() {
    return this.props.fields.map((g, i) => (
      <ExpansionPanel square expanded key={i} style={{ marginTop: 0 }}>
        <ExpansionPanelSummary style={{ cursor: 'default' }}>
          <Typography>{g.groupTitle}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={3}>
            {g.fields.map((f, i) => (
              <Grid item xs={12} md={true ? 12 : 6} key={i}>
                <Field
                  typeaheadValues={
                    this.props.typeaheadValues &&
                    this.props.typeaheadValues[f.typeAheadOn]
                      ? this.props.typeaheadValues[f.typeAheadOn]
                      : []
                  }
                  getAutoFill={
                    f.path === 'carrierPortalFields.driver_name'
                      ? this.props.getDriverProfileApiCall
                      : null
                  }
                  minWidth={g.minWidth}
                  {...f}
                  save={this.props.saveField}
                  value={this.getValue(f)}
                  oldValue={this.getOldValue(f)}
                  type={this.props.showAsStatic ? 'static-text' : f.type}
                  notifyOnChange={this.props.notifyOnChange}
                />
              </Grid>
            ))}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    ));
  }
}

export default LoadTabContent;
