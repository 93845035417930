import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter, Link } from 'react-router-dom';
import LogoTypography from '../shared/LogoTypography';
import AsyncButton from '../shared/AsyncButton';
import { loginApiCall } from './api';
import FooterLinks from './FooterLinks';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  forgotPassword: {
    textAlign: 'right'
  }
});

class Login extends Component {
  state = {
    loading: false,
    username: '',
    password: '',
    errors: {}
  };
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleLogin = event => {
    console.log('handleLogin');
    event.preventDefault();
    this.setState(
      {
        loading: true,
        errors: {}
      },
      async () => {
        const { username, password } = this.state;
        try {
          const result = await loginApiCall(username, password);
          console.log('handleLogin result', result);
          if (!this.mounted) {
            return;
          }
          this.setState({
            loading: false
          });
          if (!result.error) {
            // success!
            this.props.history.push('/dashboard');
          } else {
            switch (result.error.errorCode) {
              case 'cancelled':
                return; // another request was started before this one finished
              case 1:
                this.setState({
                  errors: {
                    username:
                      'We couldn\'t find a user with that email address. Please click the "I need an account" button below.'
                  }
                });
                return;
              case 2:
                this.setState({
                  errors: {
                    password:
                      'The password you entered is incorrect. If you forgot your password, please click the "Forgot Password" link below.'
                  }
                });
                return;
            }
          }
        } catch (err) {
          // this should not happen
          console.log(err);
          this.setState({
            loading: false
          });
        }
      }
    );
  };
  handleFieldChange = name => event => {
    this.state.errors[name] = null;
    this.setState({
      [name]: event.target.value
    });
  };
  render() {
    const { classes, history } = this.props;
    const { loading } = this.state;
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign into
          </Typography>
          <LogoTypography component="h1" variant="h4" />
          <form className={classes.form} onSubmit={this.handleLogin}>
            <FormControl
              error={!!this.state.errors.username}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                type="email"
                value={this.state.username}
                onChange={this.handleFieldChange('username')}
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
              />
              {this.state.errors.username ? (
                <FormHelperText>{this.state.errors.username}</FormHelperText>
              ) : null}
            </FormControl>
            <FormControl
              error={!!this.state.errors.password}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                value={this.state.password}
                onChange={this.handleFieldChange('password')}
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {this.state.errors.password ? (
                <FormHelperText>{this.state.errors.password}</FormHelperText>
              ) : null}
              <FormHelperText className={classes.forgotPassword}>
                <Link to="/forgot">Forgot your password?</Link>
              </FormHelperText>
            </FormControl>
            <AsyncButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={loading}
            >
              {loading ? 'Launching' : 'Launch AscendPortal'}
            </AsyncButton>
            <Button
              onClick={() => {
                history.push(`/signup`);
              }}
              fullWidth
              variant="outlined"
              color="secondary"
              className={classes.submit}
            >
              I need an account
            </Button>
          </form>
        </Paper>
        <FooterLinks />
      </main>
    );
  }
}

export default withStyles(styles)(withRouter(Login));
