import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import tabDefinition from './tabDefinition';

const styles = {
  root: {
    flexGrow: 1
  }
};

class CenteredTabs extends React.Component {
  handleChange = (e, value) => {
    this.props.handleTabChange(tabDefinition[value]);
  };
  render() {
    return (
      <Tabs
        style={{
          backgroundColor: '#1D8BF1'
        }}
        value={
          this.props.activeTab
            ? tabDefinition.indexOf(this.props.activeTab)
            : tabDefinition.indexOf('Tendered Loads')
        }
        onChange={this.handleChange}
        indicatorColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabDefinition.map(d => (
          <Tab style={{ color: 'white' }} label={d} />
        ))}
      </Tabs>
    );
  }
}

CenteredTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CenteredTabs);
