import { API_ENDPOINT } from '../config';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import get from 'lodash/get';
import { getCancelError, getAxiosOptions, saveResponse } from '../utility';
axiosCancel(axios);

export const getPaginatedLoadsApiCall = async params => {
  const endpoint = 'app/getpaginatedloads';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        params
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const acceptTendersApiCall = async loadDocIds => {
  const endpoint = 'app/accepttenders';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadDocIds,
        acceptedBy: window.localStorage.getItem('userFullName')
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const rejectTendersApiCall = async (loadDocIds, reason) => {
  const endpoint = 'app/rejecttenders';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadDocIds,
        reason,
        rejectedBy: window.localStorage.getItem('userFullName')
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const archiveLoadsApiCall = async (loadDocIds, archive) => {
  const endpoint = 'app/archiveloads';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadDocIds,
        archive
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};
