import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter } from 'react-router-dom';
import LogoTypography from '../shared/LogoTypography';
import AsyncButton from '../shared/AsyncButton';
import { forgotPasswordApiCall } from './api';
import FooterLinks from './FooterLinks';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3) * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1) *
      3}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  forgotPassword: {
    textAlign: 'right'
  }
});

class ForgotPassword extends Component {
  state = {
    loading: false,
    username: '',
    errors: {},
    emailSent: false
  };
  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleForgotPassword = event => {
    console.log('handleForgotPassword');
    event.preventDefault();
    this.setState(
      {
        loading: true,
        errors: {}
      },
      async () => {
        const { username } = this.state;
        try {
          const result = await forgotPasswordApiCall(username);
          console.log('handleForgotPassword result', result);
          if (!this.mounted) {
            return;
          }
          this.setState({
            loading: false
          });
          if (!result.error) {
            // success!
            this.setState({
              emailSent: true
            });
          } else {
            switch (result.error.errorCode) {
              case 'cancelled':
                return; // another request was started before this one finished
              case 1:
                this.setState({
                  errors: {
                    username:
                      'We couldn\'t find a user with that email address. Please click the "I need an account" button below.'
                  }
                });
                return;
            }
          }
        } catch (err) {
          // this should not happen
          console.log(err);
          this.setState({
            loading: false
          });
        }
      }
    );
  };
  handleFieldChange = name => event => {
    this.state.errors[name] = null;
    this.setState({
      [name]: event.target.value
    });
  };
  renderButtons = () => {
    const { classes, history } = this.props;

    return (
      <React.Fragment>
        <Button
          onClick={() => {
            history.push('/login');
          }}
          fullWidth
          variant="outlined"
          color="secondary"
          className={classes.submit}
        >
          I remember my password
        </Button>
        <Button
          onClick={() => {
            history.push('/signup');
          }}
          fullWidth
          variant="outlined"
          color="secondary"
          className={classes.submit}
        >
          I need an account
        </Button>
      </React.Fragment>
    );
  };
  render() {
    const { classes, history } = this.props;
    const { loading, emailSent } = this.state;
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <LogoTypography component="h1" variant="h5" />
          <br />
          {emailSent ? (
            <div>
              <Typography variant="subtitle1">
                <strong>Email sent!</strong> Please check your email for a link
                to reset your password. If you do not receive an email within a
                few minutes, please check your spam folders. If you still cannot
                find the password reset email, and the email address you entered
                is correct ({this.state.username}), email our support team with
                the link below for assistance.
              </Typography>
              {this.renderButtons()}
            </div>
          ) : (
            <React.Fragment>
              {' '}
              <Typography variant="subtitle1">
                Enter the email address associated with your{' '}
                <LogoTypography
                  style={{ display: 'inline' }}
                  component="span"
                  variant="subtitle1"
                />{' '}
                account, so we can email you a link to reset your password.
              </Typography>
              <form
                className={classes.form}
                onSubmit={this.handleForgotPassword}
              >
                <FormControl
                  error={!!this.state.errors.username}
                  margin="normal"
                  required
                  fullWidth
                >
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    type="email"
                    value={this.state.username}
                    onChange={this.handleFieldChange('username')}
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  {this.state.errors.username ? (
                    <FormHelperText>
                      {this.state.errors.username}
                    </FormHelperText>
                  ) : (
                    <FormHelperText>
                      Your email address is the same as your log in name.
                    </FormHelperText>
                  )}
                </FormControl>
                <AsyncButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  loading={loading}
                >
                  {loading ? 'Sending' : 'Send Reset Email'}
                </AsyncButton>
                {this.renderButtons()}
              </form>
            </React.Fragment>
          )}
        </Paper>
        <FooterLinks />
      </main>
    );
  }
}

export default withStyles(styles)(withRouter(ForgotPassword));
