import { API_ENDPOINT } from './config';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { getCancelError, getAxiosOptions } from './utility';
axiosCancel(axios);

let errorCount = 0;

const saveError = async payload => {
  if (errorCount > 10) {
    return false;
  }
  errorCount = errorCount + 1;
  const endpoint = 'app/saveerror';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        payload
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

const saveErrorThrottled = throttle(saveError, 1000);

window.onerror = function(msg, url, line) {
  saveErrorThrottled({ msg, url, line });
};

export default saveError;
