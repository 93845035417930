import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import SearchBar from './SearchBar';
import BackIcon from '@material-ui/icons/ArrowBack';
import LogoTypography from '../shared/LogoTypography';
import ATMSLogoTypography from '../shared/ATMSLogoTypography';
import Hidden from '@material-ui/core/Hidden';
import Popover from '@material-ui/core/Popover';

const styles = theme => ({
  appBar: {
    marginLeft: theme.drawerWidth
  },
  menuButton: {
    marginRight: 5
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: 300
  }
});

const TopBar = props => {
  const { searchProps, classes, handleDrawerOpen, title, backButton } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handlePopoverOpen(event) {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }

  function handlePopoverClose() {}

  const open = Boolean(anchorEl);

  return (
    <div>
      <AppBar
        position="fixed"
        style={{
          backgroundColor: '#EFF1F2',
          height: 30,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
          cursor: 'pointer'
        }}
        onClick={handlePopoverOpen}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            flexDirection: 'row'
          }}
        >
          <LogoTypography
            style={{ marginLeft: 15, marginRight: 5, textShadow: 'none' }}
          />
          <ATMSLogoTypography
            style={{ fontSize: '.9rem', color: 'black', textShadow: 'none' }}
            pretext="powered by "
          />
          <InfoIcon
            style={{ marginLeft: 5, color: 'black', fontSize: '.7rem' }}
          />
        </div>
        <Hidden xsDown>
          <div style={{ color: 'black', marginRight: 10 }}>
            {props.company_name || ''}
          </div>
        </Hidden>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          onClose={handlePopoverClose}
        >
          <Typography>
            Get more loads from this customer and others by using{' '}
            <ATMSLogoTypography
              component="span"
              variant="span"
              style={{ color: 'black', textShadow: 'none' }}
            />
            . It’s totally free and we guarantee you more profits and lower
            costs when you use{' '}
            <ATMSLogoTypography
              component="span"
              variant="span"
              style={{ color: 'black', textShadow: 'none' }}
            />{' '}
            to manage your logistics business. Click{' '}
            <a
              target="_blank"
              href="http://www.thefreetms.com/features/carrierportal"
            >
              here
            </a>
            .
            <IconButton
              style={{ float: 'right' }}
              className={classes.menuButton}
              onMouseUp={handlePopoverClose}
              onTouchEnd={handlePopoverClose}
              color="inherit"
            >
              <CloseIcon />
            </IconButton>
          </Typography>
        </Popover>
      </AppBar>
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{ marginTop: 30 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            className={classes.menuButton}
            onMouseUp={handleDrawerOpen}
            onTouchEnd={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          {backButton ? (
            <IconButton
              className={classes.menuButton}
              onMouseUp={backButton}
              onTouchEnd={backButton}
              color="inherit"
            >
              <BackIcon />
            </IconButton>
          ) : null}
          {searchProps ? (
            <React.Fragment>
              <SearchBar searchProps={searchProps} />
            </React.Fragment>
          ) : (
            <Typography variant="h6" color="inherit" noWrap>
              {title}
            </Typography>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

TopBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TopBar);
