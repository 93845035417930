import React, { Component } from 'react';
import Layout from '../layout/Layout';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import LoadTabs from './LoadTabs';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Hidden from '@material-ui/core/Hidden';
import debounce from 'lodash/debounce';
import WarningIcon from '@material-ui/icons/Warning';
import { amber, green } from '@material-ui/core/colors';
import LoadingOverlay from '../shared/LoadingOverlay';

import {
  getLoad,
  saveLoadFields,
  getCustomerInfo,
  handleStopReorder,
  editStop,
  saveCheckCall,
  saveMessage,
  getTypeaheadValues,
  getDriverProfile
} from './api';

import {
  rejectTendersApiCall,
  acceptTendersApiCall,
  archiveLoadsApiCall
} from '../loads/api';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import LoadTabContent from './LoadTabContent';
import EditStopsTab from './EditStopsTab';
import { assetInfo, loadBasics } from './fields';
import set from 'lodash/set';
import get from 'lodash/get';
import each from 'lodash/each';
import TenderHistory from './TenderHistory';
import Financials from './Financials';
import CheckCallHistory from './CheckCallHistory';
import MessageHistory from './MessageHistory';
import LoadActions from './LoadActions';
import SaveChangesButtons from './SaveChangesButtons';
import AssQuestionDialog from './AssQuestionDialog';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon
};
const styles = theme => ({
  main: {},
  root: {
    width: '100%',
    marginTop: 10
  },
  warning: {
    backgroundColor: amber[700]
  },
  success: {
    backgroundColor: green[600]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  toolbarStyles: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
});

class LoadContainer extends Component {
  state = {
    loading: true,
    snackbarIsOpen: false,
    typeaheadValues: {},
    updatesToSave: null
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.mounted = true;
    this.fetchData();
    this.getDriverProfileApiCall = debounce(this.getDriverProfileApiCall, 600);
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  getDriverProfileApiCall = driverName => {
    this.setState({}, async () => {
      const { match } = this.props;
      const { loadNumber } = match.params;
      const driverProfile = await getDriverProfile(driverName, loadNumber);
      if (!this.mounted) {
        return;
      }
      if (driverProfile) {
        this.state.load.carrierPortalFields = {
          ...this.state.load.carrierPortalFields,
          ...driverProfile
        };
        if (!this.state.updatesToSave) {
          this.state.updatesToSave = {};
        }
        each(driverProfile, (value, path) => {
          this.state.updatesToSave[`carrierPortalFields.${path}`] = value;
        });
        this.setState({
          updatesToSave: this.state.updatesToSave,
          load: this.state.load,
          mustRefetchOnCancel: true
        });
      }
      //set(this.state.load, 'carrierPortalFields.loadStatus', 'Active');
    });
  };

  rejectTenders = reason => {
    this.setState({}, async () => {
      await rejectTendersApiCall([this.state.load._id], reason);
      set(this.state.load, 'carrierPortalFields.loadStatus', 'Rejected');
      this.setState({
        snackbarIsOpen: `Tender Rejected Successfully!`
      });
    });
  };

  acceptTenders = reason => {
    this.setState({}, async () => {
      const result = await acceptTendersApiCall([this.state.load._id]);
      this.fetchData();
      if (result && result.cancelledOrExpiredLoadNumbers) {
        this.setState({
          snackbarIsOpen: `Unable to accept tender. It is either cancelled by the customer or expired.`,
          warning: true
        });
      } else {
        this.setState({
          snackbarIsOpen: `Tender Accepted Successfully!`
        });
      }
    });
  };

  archiveLoads = archive => {
    this.setState({}, async () => {
      await archiveLoadsApiCall([this.state.load._id], archive);
      set(
        this.state.load,
        'carrierPortalFields.loadStatus',
        archive
          ? 'Archived'
          : window._.get(
              this.state.load,
              'carrierPortalFields.prevLoadStatus',
              'Active'
            )
      );
      this.setState({
        snackbarIsOpen: `Load ${!archive ? 'Un' : ''}Archived Successfully!`
      });
    });
  };
  notifyOnChange = () => {
    if (!this.state.updatesToSave) {
      this.setState({
        updatesToSave: {}
      });
    }
  };
  // this just saves it to state, not db
  saveField = (value, path, label) => {
    this.setState({
      updatesToSave: { ...(this.state.updatesToSave || {}), [path]: value }
    });
  };
  handleSave = () => {
    each(this.state.updatesToSave, (value, path) => {
      set(this.state.load, path, value);
    });
    this.setState({ loading: true }, async () => {
      const { match } = this.props;
      const { loadNumber } = match.params;
      const result = await saveLoadFields(loadNumber, this.state.updatesToSave);
      if (window._.get(result, 'error.errorCode') === 2) {
        this.setState({
          snackbarIsOpen: window._.get(result, 'error.message'),
          warning: true
        });
        this.fetchData();
      } else {
        this.setState({
          loading: false,
          snackbarIsOpen: `Changes saved successfully!`,
          updatesToSave: null
        });
      }
    });
  };
  cancelChanges = () => {
    if (this.state.mustRefetchOnCancel) {
      this.fetchData();
    }
    this.setState(
      {
        updatesToSave: null,
        refreshing: true
      },
      () => {
        this.setState({
          refreshing: false
        });
      }
    );
  };
  setSnackbar = (snackbarIsOpen, warning = false) => {
    this.setState({
      snackbarIsOpen,
      warning
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.updatesToSave &&
      window._.get(this, 'props.match.params.activeTab', '') !==
      window._.get(prevProps, 'match.params.activeTab', '')
    ) {
      this.setState({
        updatesToSave: null
      });
    }
  }
  fetchData = () => {
    this.setState(
      {
        loading: true,
        updatesToSave: null,
        carrierPortalFieldsToReset: null,
        mustRefetchOnCancel: false
      },
      async () => {
        const { match } = this.props;
        const { loadId } = match.params;
        const load = await getLoad(loadId);
        const customerInfo = await getCustomerInfo(load.owner);
        this.setState(
          {
            loading: false,
            load,
            customerInfo
          },
          () => {
            this.getTypeaheadValuesApiCall();
          }
        );
      }
    );
  };
  getWarningMessage = () => {
    let currentStatus = window._.get(
      this,
      'state.load.carrierPortalFields.loadStatus'
    );
    if (currentStatus === 'Active') {
      return false;
    }
    if (currentStatus === 'Archived' || currentStatus === 'Imported') {
      return `${currentStatus} loads cannot be edited.`;
    }
    return `To perform this action you must first Accept the tender.`;
  };
  editStopApiCall = async (stopId, payload) => {
    if (!window._.get(this, 'state.load.carrierPortalFields.canSchedule')) {
      this.setSnackbar(
        <div>
          <strong>
            The broker does not allow you to schedule stop appointment times.
          </strong>
          <br />
          You may want to contact this broker to request permission to perform
          this action.
        </div>,
        true
      );
      return;
    }
    if (
      window._.get(this, 'state.load.carrierPortalFields.loadStatus') !==
      'Active'
    ) {
      this.setState({
        snackbarIsOpen: this.getWarningMessage(),
        warning: true
      });
      return false;
    }

    const result = await editStop(this.state.load.id, stopId, payload);
    if (window._.get(result, 'error.errorCode') === 2) {
      this.setState({
        snackbarIsOpen: window._.get(result, 'error.message'),
        warning: true
      });
      this.fetchData();
      return;
    }
    this.fetchData();
  };
  getTypeaheadValuesApiCall = async () => {
    const typeaheadValues = await getTypeaheadValues();
    this.setState({
      typeaheadValues
    });
  };
  showStatusWarning = action => {
    if (this.state.loading) {
      return;
    }
    let carrierPermMessage =
      'You may want to contact this broker to request permission to perform this action.';

    if (action === 'SCHEDULE') {
      if (!window._.get(this, 'state.load.carrierPortalFields.canSchedule')) {
        this.setSnackbar(
          <div>
            <strong>
              The broker does not allow you to schedule stop appointment times.
            </strong>
            <br />
            {carrierPermMessage}
          </div>,
          true
        );
        return true;
      }
    }
    if (action === 'REORDER') {
      if (!window._.get(this, 'state.load.carrierPortalFields.canReorder')) {
        this.setSnackbar(
          <div>
            <strong>The broker does not allow you to reorder stops.</strong>
            <br />
            {carrierPermMessage}
          </div>,
          true
        );
        return true;
      }
    }

    if (
      window._.get(this, 'state.load.carrierPortalFields.loadStatus') !==
      'Active'
    ) {
      this.setState({
        snackbarIsOpen: this.getWarningMessage(),
        warning: true
      });
      return true;
    }
    return false;
  };
  saveCheckCallApiCall = async checkCall => {
    if (
      window._.get(this, 'state.load.carrierPortalFields.loadStatus') !==
      'Active'
    ) {
      this.setState({
        snackbarIsOpen: this.getWarningMessage(),
        warning: true
      });
      return false;
    }
    const result = await saveCheckCall(this.state.load.id, checkCall);
    if (window._.get(result, 'error.errorCode') === 2) {
      this.setState({
        snackbarIsOpen: window._.get(result, 'error.message'),
        warning: true
      });
      this.fetchData();
      return;
    }
    if (!get(this.state.load, 'carrierPortalFields.checkCallHistory')) {
      set(this.state.load, 'carrierPortalFields.checkCallHistory', []);
    }
    this.state.load.carrierPortalFields.checkCallHistory.push(checkCall);
    set(this.state.load, 'carrierPortalFields.lastCheckCall', checkCall);
    this.setState({
      loading: false,
      snackbarIsOpen: `Truck status updated!`
    });
    if (
      checkCall.statusDescription &&
      [
        'Carrier Departed Delivery Location',
        'Completed Unloading at Delivery Location'
      ].includes(checkCall.statusDescription)
    ) {
      this.setState({
        showAssQuestion: true
      });
    }
  };
  saveMessageApiCall = async message => {
    message.timestamp = new Date().toISOString();
    const result = await saveMessage(this.state.load.id, message);
    if (window._.get(result, 'error.errorCode') === 2) {
      this.setState({
        snackbarIsOpen: window._.get(result, 'error.message'),
        warning: true
      });
      this.fetchData();
      return;
    }
    if (!get(this.state.load, 'carrierPortalFields.messages')) {
      set(this.state.load, 'carrierPortalFields.messages', []);
    }
    this.state.load.carrierPortalFields.messages.push(message);
    this.setState({
      loading: false,
      snackbarIsOpen: `Message Sent!`
    });
  };
  handleStopReorderApiCall = stopOrder => {
    if (
      window._.get(this, 'state.load.carrierPortalFields.loadStatus') !==
      'Active'
    ) {
      this.setState({
        snackbarIsOpen: this.getWarningMessage(),
        warning: true
      });
      return false;
    }
    if (this.showStatusWarning('REORDER')) {
      return false;
    }
    this.setState(
      {
        loading: true
      },
      async () => {
        const { match } = this.props;
        const { loadNumber } = match.params;
        const result = await handleStopReorder(loadNumber, stopOrder);

        if (window._.get(result, 'error.errorCode') === 2) {
          this.setState({
            snackbarIsOpen: window._.get(result, 'error.message'),
            warning: true
          });
          this.fetchData();
          return;
        }
        const { stopsWithLegs, calculatedMiles } = result;
        this.state.load.stopsWithLegs = stopsWithLegs;
        this.state.load.calculatedMiles = calculatedMiles;
        this.setState({
          loading: false,
          load: this.state.load
        });
      }
    );
  };
  handleTabChange = tab => {
    const { match } = this.props;
    const { loadNumber, loadId } = match.params;
    this.props.history.push(`/load/${loadId}/${loadNumber}/${tab}`);
  };
  handleBackButton = () => {
    let goTo = window.backButtonUrl;
    window.backButtonUrl = '';
    this.props.history.push(goTo);
  };
  render() {
    const { classes, history, match } = this.props;
    const { loading } = this.state;
    const Icon = variantIcon[this.state.warning ? 'warning' : 'success'];
    const { activeTab, loadNumber } = match.params;
    return (
      <Layout
        backButton={window.backButtonUrl && this.handleBackButton}
        title={`#${loadNumber}${
          window._.get(this, 'state.load.carrierPortalFields.loadStatus')
            ? ' - ' +
              window._.get(this, 'state.load.carrierPortalFields.loadStatus')
            : ''
        }`}
      >
        <AssQuestionDialog
          open={this.state.showAssQuestion}
          handleClose={() => this.setState({ showAssQuestion: false })}
          yes={() => {
            this.setState({ showAssQuestion: false });
            // show financial changes
            this.handleTabChange('New Message?showFinancialChanges=true');
          }}
        />
        <Snackbar
          onClose={() => {
            this.setState({
              snackbarIsOpen: false,
              warning: false
            });
          }}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.snackbarIsOpen ? true : false}
        >
          <SnackbarContent
            className={this.state.warning ? classes.warning : classes.success}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <Icon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {this.state.snackbarIsOpen}
              </span>
            }
          />
        </Snackbar>
        <Hidden xsDown>
          <LoadActions
            handleTabChange={this.handleTabChange}
            archiveLoads={this.archiveLoads}
            rejectTenders={this.rejectTenders}
            acceptTenders={this.acceptTenders}
            disabled={this.state.loading}
            loadStatus={window._.get(
              this,
              'state.load.carrierPortalFields.loadStatus'
            )}
          />
        </Hidden>
        <Paper className={classes.root}>
          <LoadTabs
            loadActionsProps={{
              isMobile: true,
              handleTabChange: this.handleTabChange,
              archiveLoads: this.archiveLoads,
              rejectTenders: this.rejectTenders,
              acceptTenders: this.acceptTenders,
              disabled: this.state.loading,
              loadStatus: window._.get(
                this,
                'state.load.carrierPortalFields.loadStatus'
              )
            }}
            handleTabChange={this.handleTabChange}
            activeTab={activeTab}
          />
          {this.state.loading ? <LoadingOverlay /> : null}
          {this.state.refreshing ? null : (
            <React.Fragment>
              {activeTab === 'Status, Customer, Ref No.' ? (
                <LoadTabContent
                  getDriverProfileApiCall={this.getDriverProfileApiCall}
                  typeaheadValues={this.state.typeaheadValues}
                  fields={loadBasics}
                  data={{ ...this.state.load, ...this.state.customerInfo }}
                  saveField={this.saveField}
                  notifyOnChange={this.notifyOnChange}
                />
              ) : null}
              {activeTab === 'Equipment Info' ? (
                <LoadTabContent
                  showAsStatic={this.getWarningMessage()}
                  getDriverProfileApiCall={this.getDriverProfileApiCall}
                  typeaheadValues={this.state.typeaheadValues}
                  fields={assetInfo}
                  data={this.state.load}
                  saveField={this.saveField}
                  notifyOnChange={this.notifyOnChange}
                />
              ) : null}
              {activeTab === 'View & Schedule Stops' ? (
                <EditStopsTab
                  showStatusWarning={this.showStatusWarning}
                  classes={this.props.classes}
                  editStopApiCall={this.editStopApiCall}
                  handleStopReorderApiCall={this.handleStopReorderApiCall}
                  load={this.state.load}
                />
              ) : null}
              {activeTab === 'Documents' ? (
                <TenderHistory
                  setSnackbar={this.setSnackbar}
                  openDialog={() => {
                    this.handleTabChange('Upload Doc');
                  }}
                  load={this.state.load}
                />
              ) : null}
              {activeTab === 'Financials' ? (
                <Financials
                  handleTabChange={this.handleTabChange}
                  load={this.state.load}
                />
              ) : null}
              {activeTab === 'Upload Doc' ? (
                <TenderHistory
                  setSnackbar={this.setSnackbar}
                  showUploadDoc
                  closeDialog={() => {
                    this.handleTabChange('Documents');
                  }}
                  load={this.state.load}
                />
              ) : null}
              {activeTab === 'Update Truck Status' ? (
                <CheckCallHistory
                  showStatusWarning={this.showStatusWarning}
                  saveCheckCallApiCall={this.saveCheckCallApiCall}
                  showUpdateTruckStatus
                  closeDialog={() => {
                    this.handleTabChange('Truck Status Log');
                  }}
                  load={this.state.load}
                />
              ) : null}
              {activeTab === 'Truck Status Log' ? (
                <CheckCallHistory
                  openDialog={() => {
                    this.handleTabChange('Update Truck Status');
                  }}
                  saveCheckCallApiCall={this.saveCheckCallApiCall}
                  load={this.state.load}
                />
              ) : null}
              {activeTab === 'Messages' ? (
                <MessageHistory
                  openDialog={() => {
                    this.handleTabChange('New Message');
                  }}
                  saveMessageApiCall={this.saveMessageApiCall}
                  load={this.state.load}
                />
              ) : null}
              {activeTab === 'New Message' ? (
                <MessageHistory
                  typeaheadValues={this.state.typeaheadValues}
                  showDialog
                  closeDialog={() => {
                    this.handleTabChange('Messages');
                  }}
                  saveMessageApiCall={this.saveMessageApiCall}
                  load={this.state.load}
                />
              ) : null}
              {['Equipment Info', 'Status, Customer, Ref No.'].includes(
                activeTab
              ) ? (
                <SaveChangesButtons
                  cancelChanges={this.cancelChanges}
                  saving={this.state.loading}
                  handleSave={this.handleSave}
                  isClean={!this.state.updatesToSave}
                  classes={this.props.classes}
                />
              ) : null}
            </React.Fragment>
          )}
        </Paper>
      </Layout>
    );
  }
}

export default withStyles(styles)(withRouter(LoadContainer));
