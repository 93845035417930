import get from 'lodash/get';
import each from 'lodash/each';

export const FIELDS_WITH_OLD_VALUES = [
  'actions',
  'dateTime',
  'block_address',
  'cargo',
  'reference'
];

// This means that the axios-cancel plugin cancelled the request
// due to a newer request starting before the previous finished
export const getCancelError = err => {
  console.log('err', err.message);
  if (get(err, 'message', []).includes('Request failed with status code 401')) {
    window.localStorage.clear();
    window.location.replace(`${window.location.origin}/login`);
  }
  if (get(err, 'message', []).includes('cancelRequest')) {
    return {
      error: {
        errorCode: 'cancelled'
      }
    };
  }
  return null;
};

export const getAxiosOptions = endpoint => {
  const toReturn = {
    requestId: endpoint,
    headers: endpoint.includes('app/')
      ? { authorization: window.localStorage.getItem('authtoken') }
      : {}
  };

  if (endpoint.includes('uploadfile')) {
    toReturn.headers['Content-Type'] = 'multipart/form-data';
  }
  return toReturn;
};

export const saveResponse = data => {
  each(data, (value, key) => {
    window.localStorage.setItem(key, value);
  });
};

export const getPlurality = length => {
  if (length === 0 || length > 1) return 's';
  return '';
};

export const formatDate = inputDate => {
  if (!inputDate) {
    return 'Not Entered';
  }
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    // Months use 0 index.
    return (
      date.getUTCMonth() +
      1 +
      '/' +
      date.getUTCDate() +
      '/' +
      date.getUTCFullYear()
    );
  }
  return inputDate;
};
