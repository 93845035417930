import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import set from 'lodash/set';
import get from 'lodash/get';
import find from 'lodash/find';
import Field from '../shared/Field';

const HIDE_CURRENT_LOCATION_ON = [
  'Arrived at Delivery Location',
  'Arrived at Pick-Up Location',
  'Attempted Delivery',
  'Carrier Departed Delivery Location',
  'Carrier Departed Pick-up Location with Shipment',
  'Completed Loading at Pick-Up Location',
  'Completed Unloading at Delivery Location',
  'Loaded on Truck'
];

const toolbarStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  },
  showMobileOnly: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

const getTime = () => {
  var d = new Date(),
    h = (d.getHours() < 10 ? '0' : '') + d.getHours(),
    m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
  return h + ':' + m;
};

const CheckCall = React.memo(props => {
  if (!props.load || !props.load.stops) {
    return null;
  }
  const { dialogOpen, closeDialog, fullScreen, load } = props;

  const [state, setState] = React.useState({
    Date: new Date().toISOString().split('T')[0],
    Time: getTime()
  });

  const fields = [
    {
      label: 'Status Description',
      type: 'select',
      placeholder: 'select a status',
      path: 'statusDescription',
      options: [
        'Arrived at Delivery Location',
        'Arrived at Pick-Up Location',
        'Attempted Delivery',
        'At prior load',
        'Carrier Departed Delivery Location',
        'Carrier Departed Pick-up Location with Shipment',
        'Completed Loading at Pick-Up Location',
        'Completed Unloading at Delivery Location',
        'Delivery Not Completed',
        'En Route to Pickup Location',
        'En Route to Delivery Location',
        'Estimated Delivery',
        'Loaded on Truck',
        'Paperwork Received - Did not Receive Shipment or Equipment',
        'Refused by Consignee',
        'Shipment Cancelled',
        'Shipment Damaged',
        'Shipment Delayed',
        'Shipment Returned to Shipper'
      ],
      value: state.statusDescription || '',
      handleOnChange: e => {
        state.statusDescription = e.target.value;
        setState({ ...state });
      }
    },
    {
      label: 'Stop Location',
      type: 'select',
      placeholder: 'select a stop location',
      path: 'stopLocation',
      options: load.stops.stopOrder.map(id => {
        return load.stops[id].name;
      }),
      value: state.stopLocation || '',
      handleOnChange: e => {
        state.stopLocation = e.target.value;
        setState({ ...state });
      }
    },
    {
      label: 'Current Location',
      type: 'text',
      placeholder: 'enter address or city, state',
      path: 'currentLocation',
      value: state.currentLocation || '',
      handleOnBlur: () => {},

      handleOnChange: e => {
        state.currentLocation = e.target.value;
        setState({ ...state });
      }
    },
    {
      label: 'Details or Notes',
      type: 'textarea',
      placeholder: '',
      path: 'notes',
      value: state.notes || '',
      handleOnBlur: () => {},
      handleOnChange: e => {
        state.notes = e.target.value;
        setState({ ...state });
      }
    },
    {
      label: 'Date / Time of Event',
      type: 'datetime',
      path: 'dateTime',
      setValue: (value, propName) => {
        console.log('propName', propName);
        console.log('value', value);
        set(state, `${propName}`, value);
        setState({ ...state });
      },
      save: () => {},
      dateValue: state.Date,
      timeValue: state.Time,
      value: state.datetime
    }
  ];

  function handleDialogClose() {
    setState({});
    closeDialog();
  }
  function handleSave() {
    if (HIDE_CURRENT_LOCATION_ON.includes(state.statusDescription)) {
      state.currentLocation = null;
      state.coords = null;
      if (state.stopLocation) {
        state.currentLocation = state.stopLocation;
        const stopId = find(load.stops.stopOrder, id => {
          return load.stops[id].name === state.stopLocation;
        });
        if (stopId && load.stops[stopId].coordinates) {
          state.coords = {
            latitude: load.stops[stopId].coordinates.lat,
            longitude: load.stops[stopId].coordinates.long
          };
        }
      }
    }
    props.saveCheckCallApiCall({ ...state });
    setState({});
    closeDialog();
  }

  return (
    <Dialog
      disableBackdropClick
      md="lg"
      fullWidth
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Update your truck status</DialogTitle>
      <DialogContent>
        <div>
          {fields.map(f => {
            if (
              f.path === 'currentLocation' &&
              HIDE_CURRENT_LOCATION_ON.includes(state.statusDescription)
            ) {
              return null;
            }
            return (
              <div style={{ marginBottom: 15 }}>
                <Field {...f} />
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 25 }}>
        <Button onClick={handleDialogClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

CheckCall.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(toolbarStyles)(CheckCall);
