import React from 'react';
import { useDropzone } from 'react-dropzone';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DocumentTypeMultiSelect from './DocumentTypeMultiSelect';
import AsyncButton from '../shared/AsyncButton';
import Field from '../shared/Field';

const fieldDef = {
  label: 'File Description (optional)',
  type: 'textarea',
  placeholder: '',
  path: 'description',
  handleOnBlur: () => {}
};

function UploadFile(props) {
  const {
    fullScreen,
    dialogOpen,
    handleDialogClose,
    uploadFileApiCall,
    load,
    classes,
    handleDocumentTypeChange,
    handleDescriptionOnChange,
    documentType,
    uploading,
    description
  } = props;
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: 1.2e7,
    onDropRejected: e => {
      props.setSnackbar(
        'The file you selected is too large. The max file size is 12mb.',
        true
      );
    }
  });
  const handleSave = () => {
    uploadFileApiCall(acceptedFiles[0]);
  };
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Dialog
      disableBackdropClick
      md="lg"
      fullWidth
      fullScreen={fullScreen}
      open={dialogOpen || false}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Attach file to load
        <br />
        <small>Customer Ref #{load && load.id}</small>
      </DialogTitle>
      <DialogContent>
        <div className="container">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <p>Drag and drop file here to upload.</p>
            <Button color="primary" variant="contained" onClick={open}>
              Select Document
            </Button>
          </div>
          {acceptedFiles.length ? (
            <div>
              <aside style={{ paddingLeft: 0, marginBottom: -10 }}>
                <h4 style={{ fontSize: 14 }}>
                  You selected the file below:
                  <br />
                  <small>{acceptedFiles[0].path}</small>
                </h4>
              </aside>
              <Field
                {...fieldDef}
                value={description}
                handleOnChange={handleDescriptionOnChange}
              />
              <br />
              <DocumentTypeMultiSelect
                handleChange={handleDocumentTypeChange}
                documentType={documentType}
              />
            </div>
          ) : null}
        </div>
      </DialogContent>
      <DialogActions style={{ paddingBottom: fullScreen ? 25 : 10 }}>
        <Button onClick={handleDialogClose} variant="contained">
          Cancel
        </Button>
        <AsyncButton
          loading={uploading}
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!acceptedFiles.length || uploading}
          onClick={handleSave}
        >
          Upload Document
          <CloudUploadIcon className={classes.rightIcon} />
        </AsyncButton>
      </DialogActions>
    </Dialog>
  );
}

export default UploadFile;
