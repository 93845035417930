import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LoadActions from './LoadActions';
//expand_more

const toolbarStyles = theme => ({
  root: {
    height: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    paddingLeft: 30,
    color: theme.palette.primary.main,
    backgroundColor: 'white'
  }
});

const TopToolbar = React.memo(props => {
  const { value, tabDefinition, onChange, classes, loadActionsProps } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  function handleChange(i) {
    setAnchorEl(null);
    onChange(null, i);
  }
  return (
    <div className={classes.root}>
      <Button
        style={{ marginRight: 10 }}
        fullWidth
        color="primary"
        variant="contained"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {`${tabDefinition[value]}`} <ExpandMoreIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {tabDefinition.map((d, i) => (
          <MenuItem
            style={{ paddingRight: 150 }}
            selected={i === value}
            key={d}
            onClick={() => handleChange(i)}
          >
            {d}
          </MenuItem>
        ))}
      </Menu>
      <LoadActions {...loadActionsProps} />
    </div>
  );
});

TopToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

export default withStyles(toolbarStyles)(TopToolbar);
