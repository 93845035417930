import each from 'lodash/each';
import get from 'lodash/get';
import some from 'lodash/some';
import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { formatDate } from '../utility';

const DragHandle = SortableHandle(({ style }) => (
  <span style={{ ...style, ...{ cursor: 'move' } }}>
    <DragIndicatorIcon />
  </span>
));

export const stopActions = {
  Pickup: {
    type: 'Pickup',
    showOnCustomerDocs: true,
    showFreight: true,
    changeTruckUnit: false,
    changeDriver: false,
    changePowerUnit: false,
    changeTrailer: false,
    color: '#E2EFDA',
    labelStyle: 'success',
    iconClassName: 'fa-arrow-up'
  },
  Delivery: {
    type: 'Delivery',
    showOnCustomerDocs: true,
    showFreight: true,
    changeTruckUnit: false,
    changeDriver: false,
    changePowerUnit: false,
    changeTrailer: false,
    color: '#EFDFDF',
    labelStyle: 'danger',
    iconClassName: 'fa-arrow-down'
  },
  'Drop Trailer': {
    type: 'Drop Trailer',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: 'Nullify',
    changeDriver: 'Nullify',
    changePowerUnit: 'Nullify',
    changeTrailer: false,
    showChangeAssetDetails: true,
    color: '#FCF8E5',
    labelStyle: 'info',
    iconClassName: 'fa-cubes'
  },
  'Hook Trailer': {
    type: 'Hook Trailer',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: true,
    changeDriver: true,
    changePowerUnit: true,
    changeTrailer: false,
    showChangeAssetDetails: true,
    color: '#FCF8E5',
    labelStyle: 'info',
    iconClassName: 'fa-cubes'
  },
  'Change Asset Group': {
    type: 'Change Asset Group',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: true,
    changeDriver: true,
    changePowerUnit: true,
    changeTrailer: true,
    showChangeAssetDetails: true,
    color: '#FCF8E5',
    labelStyle: 'info',
    iconClassName: 'fa-truck'
  },
  'Change Driver': {
    type: 'Change Driver',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: 'Nullify',
    changeDriver: true,
    changePowerUnit: false,
    changeTrailer: false,
    showChangeAssetDetails: true,
    color: '#FCF8E5',
    labelStyle: 'info',
    iconClassName: 'fa-user'
  },
  'Change Power Unit': {
    type: 'Change Power Unit',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: 'Nullify',
    changeDriver: false,
    changePowerUnit: true,
    changeTrailer: false,
    showChangeAssetDetails: true,
    color: '#FCF8E5',
    labelStyle: 'info',
    iconClassName: 'atms-frontal-truck'
  },
  'Change Trailer': {
    type: 'Change Trailer',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: 'Nullify',
    changeDriver: false,
    changePowerUnit: false,
    changeTrailer: true,
    showChangeAssetDetails: true,
    color: '#FCF8E5',
    labelStyle: 'info',
    iconClassName: 'fa-cubes'
  },
  Detention: {
    type: 'Detention',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: false,
    changeDriver: false,
    changePowerUnit: false,
    changeTrailer: false,
    color: '#FCF8E5'
  },
  Storage: {
    type: 'Storage',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: false,
    changeDriver: false,
    changePowerUnit: false,
    changeTrailer: false,
    color: '#FCF8E5'
  },
  Accident: {
    type: 'Accident',
    showOnCustomerDocs: false,
    showFreight: false,
    changeTruckUnit: false,
    changeDriver: false,
    changePowerUnit: false,
    changeTrailer: false,
    color: '#EBE2FD'
  }
};

export const stopColumns = [
  { title: 'Stop Order', className: 'center-cell', key: 'order', size: 1 },
  {
    title: 'Action(s)',
    className: 'center-cell',
    key: 'actions',
    size: 1,
    render: (v, stop) => {
      return (stop.actions || []).map(a => {
        const actionDef = a ? stopActions[a.type] : null;
        return actionDef ? <div key={a.id}>{a.type}</div> : null;
      });
    }
  },
  {
    title: 'Originally Contracted Date/Time',
    className: '',
    size: 1.5,
    key: 'dateTime',
    render: (v, stop) => {
      return (stop.actions || []).map(a => (
        <div key={a.id}>
          {`${a.date || 'Not Entered'}${a.dateEnd ? ' - ' + a.dateEnd : ''}`}{' '}
          <Tooltip
            classes={{
              tooltip: 'tooltipFontStyle'
            }}
            title={`Driver Instructions: ${a.driverInstructions ||
              'Not Entered'}`}
          >
            <span style={{ paddingLeft: 5 }}>{` ${(
              a.driverInstructions || ''
            ).substring(0, 15)}${
              (a.driverInstructions || '').length > 15 ? '...' : ''
            }`}</span>
          </Tooltip>
        </div>
      ));
    }
  },
  {
    title: 'Scheduled Window Date/Time',
    className: '',
    size: 1.3,
    key: 'scheduledWindowBegin',
    render: (v, stop, actions) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'left'
          }}
        >
          {!actions || !Object.keys(actions).length ? (
            'Click to re-schedule stop'
          ) : (
            <div>
              {(stop.actions || []).map(a => (
                <div key={a.id}>
                  <div style={{ whiteSpace: 'nowrap' }}>
                    {`${formatDate(
                      get(actions, `${a.id}.scheduledWindowBeginDate`)
                    )} ${get(actions, `${a.id}.scheduledWindowBeginTime`) ||
                      ''}${
                      get(actions, `${a.id}.scheduledWindowEndDate`)
                        ? ' - '
                        : ''
                    }`}
                  </div>
                  {get(actions, `${a.id}.scheduledWindowEndDate`) ? (
                    <div>
                      {`${formatDate(
                        get(actions, `${a.id}.scheduledWindowEndDate`)
                      )} ${get(actions, `${a.id}.scheduledWindowEndTime`) ||
                        ''}`}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          )}
          <EditIcon
            style={{ paddingLeft: 5 }}
            fontSize="small"
            color="primary"
          />
        </div>
      );
    }
  },
  {
    title: 'Address',
    className: '',
    key: 'block_address',
    size: 2,
    render: (v, stop) => {
      return (
        <div>
          <strong>{stop.name}</strong>
          <br />
          <div
            style={{
              whiteSpace: 'pre-wrap'
            }}
          >
            {stop.block_address}
          </div>
        </div>
      );
    }
  },
  {
    title: 'Cargo',
    className: '',
    key: 'cargo',
    size: 1,
    render: (v, stop) => {
      return (stop.actions || []).map(a => (
        <Tooltip
          key={a.id}
          classes={{
            tooltip: 'tooltipFontStyle'
          }}
          title={`Cargo: ${a.cargo || 'Not Entered'}`}
        >
          <div>{`${(a.cargo || '').substring(0, 10)}${
            (a.cargo || '').length > 10 ? '...' : ''
          }`}</div>
        </Tooltip>
      ));
    }
  },
  {
    title: 'Reference#',
    className: 'reference',
    key: 'reference',
    size: 1,
    render: (v, stop, actions, load) => {
      if (load && load.orders && load.orders.length) {
        return (stop.actions || []).map(a => (
          <table key={a.id}  className="table table-bordered">
            <thead>
              <tr>
                <th>PO Number</th>
                <th>Pallets</th>
                <th>Cases</th>
                <th>Weight</th>
                <th>Cubes</th>
              </tr>
            </thead>
            <tbody>
              {(a.reference || '').split(/\n/).map(function(o) {
                if (o) {
                  var myOrder;
                  some(load.orders, function(so) {
                    if (so.po_num === o) {
                      myOrder = so;
                      return true;
                    }
                  });
                  if (!myOrder) {
                    return null;
                  }
                  return (
                    <tr key={o}>
                      <td>{o}</td>
                      <td>{myOrder.pallets}</td>
                      <td>{myOrder.cases}</td>
                      <td>{myOrder.weight}</td>
                      <td>{myOrder.order_cubes}</td>
                    </tr>
                  );
                } else {
                  return '';
                }
              })}
            </tbody>
          </table>
        ));
      }
      return (stop.actions || []).map(a => (
        <Tooltip
          key={a.id}
          classes={{
            tooltip: 'tooltipFontStyle'
          }}
          title={`Reference: ${a.reference || 'Not Entered'}`}
        >
          <div>{`${(a.reference || '').substring(0, 10)}${
            (a.reference || '').length > 10 ? '...' : ''
          }`}</div>
        </Tooltip>
      ));
    }
  },
  {
    title: 'Reorder',
    className: '',
    key: 'reorder',
    size: 0.5,
    render: (v, stop) => {
      return <DragHandle />;
    }
  }
];

// set column widths
let totalColumns = 0;
each(stopColumns, c => {
  totalColumns += c.size;
});
let sizePerColumn = 100 / totalColumns;
each(stopColumns, c => {
  c.columnStyle = { width: `${c.size * sizePerColumn}%` };
});
