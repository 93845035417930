import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  spinner: {
    marginLeft: 15
  }
};

const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress color="secondary" className={props.classes.spinner} size={20} />
));

const AsyncButton = props => {
  const { children, loading, ...rest } = props;
  return (
    <Button disabled={loading ? true : false} {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};

export default AsyncButton;
