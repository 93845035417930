import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  mobile: {
    height: 64,
    display: 'inline'
  },
  desktop: {
    marginBottom: 15,
    marginTop: -3
  }
});

const LoadActions = React.memo(props => {
  const { classes, handleTabChange, loadStatus, disabled, isMobile } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [rejectValue, handleRejectChange] = React.useState('');
  function handleReject() {
    setDialogOpen(true);
  }
  function handleAccept() {
    props.acceptTenders();
    handleClose();
  }
  function handleArchive() {
    props.archiveLoads(true);
    handleClose();
  }
  function handleUnArchive() {
    props.archiveLoads(false);
    handleClose();
  }
  function handleRejectSave() {
    props.rejectTenders(rejectValue);
    handleDialogClose();
    handleClose();
  }

  function handleDialogClose() {
    handleRejectChange('');
    setDialogOpen(false);
  }
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const loadActions = [
    {
      label: `Accept Tender`,
      handleClick: handleAccept,
      onlyShowOn: ['Tendered', 'Revised Tender']
    },
    {
      label: `Reject Tender`,
      handleClick: handleReject,
      onlyShowOn: ['Tendered', 'Revised Tender']
    },
    {
      label: `Schedule Appointments`,
      handleClick: () => {
        handleClose();
        handleTabChange(`View & Schedule Stops`);
      },
      onlyShowOn: ['Active']
    },
    {
      label: `Update Your Truck Status`,
      handleClick: () => {
        handleClose();
        handleTabChange(`Update Truck Status`);
      },
      onlyShowOn: ['Active']
    },
    {
      label: `Propose Financial Changes / Send Message`,
      handleClick: () => {
        handleClose();
        handleTabChange(`New Message`);
      },
      onlyShowOn: ['Tendered', 'Revised Tender', 'Active']
    },
    {
      label: `Upload Docs`,
      handleClick: () => {
        handleClose();
        handleTabChange(`Upload Doc`);
      }
    },
    {
      label: `Archive Load`,
      handleClick: handleArchive,
      onlyShowOn: ['Rejected', 'Active', 'Expired', 'Cancelled']
    },
    {
      label: `Unarchive Load`,
      handleClick: handleUnArchive,
      onlyShowOn: ['Archived']
    }
  ];

  function renderDialog(fullScreen) {
    return (
      <Dialog
        fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reason for rejection</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please explain to the customer why you are rejecting these tender(s)
          </DialogContentText>
          <Input
            onChange={e => handleRejectChange(e.target.value)}
            value={rejectValue}
            rowsMax={10}
            multiline
            autoFocus
            fullWidth
          />
        </DialogContent>
        <DialogActions style={{ paddingBottom: fullScreen ? 25 : 10 }}>
          <Button onClick={handleDialogClose} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleRejectSave}
            variant="contained"
            color="primary"
          >
            {`Reject Tender`}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <div>
      <Hidden smUp>{renderDialog(true)}</Hidden>
      <Hidden xsDown>{renderDialog(false)}</Hidden>
      <div className={isMobile ? classes.mobile : classes.desktop}>
        <Button
          style={{ marginRight: isMobile ? 30 : 'inherit' }}
          disabled={disabled}
          color="secondary"
          variant="contained"
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {isMobile ? (
            <MoreVertIcon />
          ) : (
            <React.Fragment>
              {`Load Actions`} <ExpandMoreIcon />
            </React.Fragment>
          )}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled>{`Portal Status: ${loadStatus}`}</MenuItem>
          {loadActions.map((a, i) => {
            if (a.onlyShowOn && !a.onlyShowOn.includes(loadStatus)) {
              return null;
            }
            return (
              <MenuItem key={i} onClick={a.handleClick}>
                {a.label}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
});

LoadActions.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired
};

export default withStyles(styles)(LoadActions);
