import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { getAttachment, uploadFile } from './api';
import UploadFile from './UploadFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoadingOverlay from '../shared/LoadingOverlay';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  toolbarStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
});

class TenderHistory extends React.Component {
  state = {
    documentType: [],
    uploading: false,
    url: null
  };
  getAttachmentApiCall = (pdfDocId, filename) => {
    this.setState(
      {
        uploading: true
      },
      async () => {
        const url = await getAttachment(pdfDocId, filename);
        this.setState({
          url,
          filename,
          uploading: false
        });
      }
    );
  };
  handleUrlClose = () => {
    this.setState({ url: null, filename: null });
  };
  viewUrl = () => {
    window.open(this.state.url, '_blank');
    this.handleUrlClose();
  };
  downloadUrl = () => {
    const link = document.createElement('a');
    link.href = this.state.url;
    link.setAttribute('download', this.state.filename);
    document.body.appendChild(link);
    link.click();
    this.handleUrlClose();
  };
  uploadFileApiCall = file => {
    this.setState(
      {
        uploading: true
      },
      async () => {
        console.log('file', file);
        const result = await uploadFile(
          file,
          this.state.documentType,
          this.props.load._id,
          this.state.description
        );

        if (window._.get(result, 'error.errorCode') === 2) {
           this.props.setSnackbar(window._.get(result, 'error.message'), true)
         }

        if (result._id) {
          this.props.load.loadDocs.unshift(result);
        }
        this.setState({ uploading: false });
        this.props.closeDialog();
      }
    );
  };
  handleDocumentTypeChange = event => {
    this.setState({ documentType: event.target.value });
  };
  handleDescriptionOnChange = event => {
    this.setState({ description: event.target.value });
  };
  renderUploadDialog = fullScreen => {
    const { classes, showUploadDoc, closeDialog } = this.props;
    return (
      <UploadFile
        setSnackbar={this.props.setSnackbar}
        description={this.state.description || ''}
        fullScreen={fullScreen}
        uploading={this.state.uploading}
        documentType={this.state.documentType}
        handleDocumentTypeChange={this.handleDocumentTypeChange}
        handleDescriptionOnChange={this.handleDescriptionOnChange}
        classes={classes}
        load={this.props.load}
        uploadFileApiCall={this.uploadFileApiCall}
        handleDialogClose={closeDialog}
        dialogOpen={showUploadDoc}
      />
    );
  };
  renderUrlDialog = fullScreen => {
    return (
      <Dialog
        fullScreen={fullScreen}
        open={this.state.url ? true : false}
        onClose={this.handleUrlClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ marginBottom: 10, marginTop: 10 }}>
          <DialogContentText id="alert-dialog-description">
            <strong>Select whether you want to download or view:</strong>
            <br />
            <small>
              <strong>{this.state.filename}</strong>
            </small>
          </DialogContentText>
          <Button
            variant="contained"
            style={{ marginRight: 5 }}
            onClick={this.handleUrlClose}
          >
            Cancel
          </Button>
          <Button
            style={{ marginRight: 5 }}
            onClick={this.downloadUrl}
            variant="contained"
            color="secondary"
          >
            Download .PDF
          </Button>
          <Button
            onClick={this.viewUrl}
            variant="contained"
            color="primary"
            autoFocus
          >
            View .PDF
          </Button>
        </DialogContent>
      </Dialog>
    );
  };
  render() {
    const { classes, load, openDialog } = this.props;

    return (
      <div>
        <div style={{ paddingLeft: 8, marginTop: 10, marginBottom: 10 }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={openDialog}
          >
            Upload a new document to your customer
            <CloudUploadIcon className={classes.rightIcon} />
          </Button>
          <Hidden smUp>{this.renderUploadDialog(true)}</Hidden>
          <Hidden xsDown>{this.renderUploadDialog(false)}</Hidden>
        </div>
        <Divider />

        <List
          subheader={
            <ListSubheader>Select a document below to download</ListSubheader>
          }
          className={classes.root}
        >
          <Divider />

          {window._.get(load, 'loadDocs', []).map((row, i) => {
            if (!row.pdfDocId && !row._id) {
              return null;
            }
            if (row.pdfDocId) {
              return (
                <ListItem
                  style={{ width: '100%' }}
                  divider
                  key={row.pdfDocId}
                  role={undefined}
                  button
                  onClick={() =>
                    this.getAttachmentApiCall(row.pdfDocId, row.filename)
                  }
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      style: {
                        color: 'black',
                        fontSize: '1em'
                      }
                    }}
                    primary={
                      <div>
                        <strong>Document Type</strong>
                        <br />
                        <div>Carrier Confirmation / Load Tender</div>
                        <strong>Tendered Date / Time</strong>
                        <br />
                        <div>{new Date(row.date).toLocaleString()}</div>
                        <strong>Tendered By</strong>
                        <br />
                        <div>{row.tenderedBy}</div>
                        <strong>Distance</strong>
                        <br />
                        <div>{`${row.distance} miles`}</div>
                        <strong>Total Amount</strong>
                        <br />
                        <div>{`$${(+(row.totalAmount || 0))
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</div>
                      </div>
                    }
                  />
                </ListItem>
              );
            }
            return (
              <ListItem
                style={{ width: '100%' }}
                divider
                key={row._id}
                role={undefined}
                button
                onClick={() => window.open(row.url, '_blank')}
              >
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'subtitle1',
                    style: {
                      color: 'black',
                      fontSize: '.85em'
                    }
                  }}
                  primary={
                    <div>
                      <strong>File Name</strong>
                      <br />
                      <div>{row.filename}</div>
                      <strong>Description</strong>
                      <br />
                      <div>{row.description || 'Not Entered'}</div>
                      <strong>Document Type</strong>
                      <br />
                      <div>
                        {row && row.tags && row.tags.length
                          ? row.tags.join(', ')
                          : 'Not Entered'}
                      </div>
                      <strong>Date / Time</strong>
                      <br />
                      <div>{new Date(row.timestamp).toLocaleString()}</div>
                      <strong>Uploaded By</strong>
                      <br />
                      <div>{row.user.full_name}</div>
                    </div>
                  }
                />
              </ListItem>
            );
          })}
        </List>
        {this.state.uploading ? <LoadingOverlay /> : null}
        <Hidden smUp>{this.renderUrlDialog(true)}</Hidden>
        <Hidden xsDown>{this.renderUrlDialog(false)}</Hidden>
      </div>
    );
  }
}

TenderHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TenderHistory);
