import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Hidden from '@material-ui/core/Hidden';
import EnhancedTableHead from './EnhancedTableHead';
import Checkbox from '@material-ui/core/Checkbox';
import TablePaginationActions from './TablePaginationActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppList from './AppList';

const styles = theme => ({
  table: {
    minWidth: 500,
    tableLayout: 'fixed'
  },
  tableWrapper: {
    overflowX: 'auto',
    position: 'relative'
  },
  defaultColumnWidth: {
    width: '33.33%'
  },
  checkBoxColumn: {
    width: 45
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,0.8)'
  },
  overlayContent: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#555'
  }
});

class AppTable extends React.Component {
  renderPagination = isMobile => {
    const { rowsPerPage, page, totalRows = 0 } = this.props;
    return (
      <TablePagination
        rowsPerPageOptions={isMobile ? [] : [5, 10, 25]}
        colSpan={3}
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          native: true
        }}
        onChangePage={this.props.handleChangePage}
        onChangeRowsPerPage={this.props.handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    );
  };
  render() {
    const {
      classes,
      columns,
      rows = [],
      rowsPerPage,
      page,
      order,
      orderBy,
      selected = [],
      totalRows = 0,
      loading = false,
      activeTab
    } = this.props;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, totalRows - page * rowsPerPage);

    return (
      <div className={classes.tableWrapper}>
        {loading ? (
          <div className={classes.overlay}>
            <div className={classes.overlayContent}>
              <CircularProgress disableShrink />
            </div>
          </div>
        ) : null}
        <Hidden smUp>
          <AppList
            columns={columns}
            isSelectMode={this.props.isSelectMode}
            selectRow={this.props.selectRow}
            handleClick={this.props.handleClick}
            rows={rows}
            selected={selected}
            activeTab={activeTab}
          />
          {this.renderPagination(true)}
        </Hidden>
        <Hidden xsDown>
          <Table className={classes.table}>
            <colgroup>
              <col className={classes.checkBoxColumn} />
              {columns.map(c =>
                c.dontShowOn && c.dontShowOn.includes(activeTab) ? null : (
                  <col key={c.key} style={c.columnStyle} />
                )
              )}
            </colgroup>
            <EnhancedTableHead
              activeTab={activeTab}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={this.props.handleSelectAllClick}
              handleRequestSort={this.props.handleRequestSort}
              rowCount={rows.length}
              columns={columns}
            />
            <TableBody>
              {rows.map(row => (
                <TableRow className="clickable" hover key={row.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={e => this.props.selectRow(e, row.id)}
                      checked={selected.includes(row.id)}
                    />
                  </TableCell>
                  {columns.map(c =>
                    c.dontShowOn && c.dontShowOn.includes(activeTab) ? null : (
                      <TableCell
                        key={c.key}
                        className={c.className || ''}
                        align={c.align}
                        onClick={() => {
                          this.props.handleClick(row);
                        }}
                      >
                        {c.render
                          ? c.render(window._.get(row, c.key), row)
                          : window._.get(row, c.key)}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>{this.renderPagination()}</TableRow>
            </TableFooter>
          </Table>
        </Hidden>
      </div>
    );
  }
}

AppTable.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppTable);
