import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.handleRequestSort(event, property);
  };

  render() {
    const {
      handleSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      columns,
      activeTab
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={handleSelectAllClick}
            />
          </TableCell>
          {columns.map(
            c =>
              c.dontShowOn && c.dontShowOn.includes(activeTab) ? null : (
                <TableCell
                  key={c.key}
                  padding={c.disablePadding ? 'none' : 'default'}
                  sortDirection={orderBy === c.key ? order : false}
                  align={c.align}
                >
                  {/* <Tooltip
                  title="Sort"
                  placement={c.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                > */}
                  <TableSortLabel
                    hideSortIcon={c.hideSortIcon}
                    active={orderBy === c.key}
                    direction={order}
                    onClick={c.hideSortIcon ? () => {} : this.createSortHandler(c.key)}
                  >
                    {c.title}
                  </TableSortLabel>
                  {/* </Tooltip> */}
                </TableCell>
              ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default EnhancedTableHead;
