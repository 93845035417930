import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { withRouter } from 'react-router-dom';
import AlertInvite from '../account/AlertInvite';
import { getInviteDetails, acceptLink } from '../account/api';

const styles = theme => ({
  root: {
    display: 'flex'
  },
  disableTransition: {
    transition: 'none'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: theme.drawerWidth
  },
  content: {
    marginTop: 90,
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3)
    }
  }
});

class Layout extends React.PureComponent {
  state = {
    mobileOpen: false,
    inviteDialogOpen: false
  };
  async componentDidMount() {
    if (!window.localStorage.getItem('authtoken')) {
      this.props.history.push('/login');
    }
    this.inviteCode = window.localStorage.getItem('code');
    if (this.inviteCode) {
      const inviteDetails = await getInviteDetails(this.inviteCode);
      if (inviteDetails) {
        this.setState({ inviteDetails, inviteDialogOpen: true });
      }
    }
    if (window.localStorage.getItem('company_name')) {
      this.setState({
        company_name: window.localStorage.getItem('company_name')
      });
    }
  }
  handleDrawerOpen = () => {
    this.setState(
      state => ({ mobileOpen: true, sidebarEnabled: false }),
      () => {
        setTimeout(() => {
          this.setState({
            sidebarEnabled: true
          });
        }, 200);
      }
    );
  };
  handleDrawerClose = () => {
    this.setState(state => ({ mobileOpen: false }));
  };
  closeDrawer = () => {
    this.setState({
      mobileOpen: false
    });
  };
  logout = () => {
    window.localStorage.clear();
    this.props.history.push('/login');
  };
  handleInviteDialogClose = () => {
    window.localStorage.removeItem('code');
    this.setState({ inviteDialogOpen: false });
    this.props.history.push(this.props.history.pathname);
  };
  acceptInvite = async () => {
    const result = await acceptLink(this.inviteCode);
    console.log('​-----------------------------------------');
    console.log('​Layout -> acceptInvite -> result', result);
    console.log('​-----------------------------------------');
    this.handleInviteDialogClose();
  };
  declineInvite = () => {
    this.handleInviteDialogClose();
  };
  render() {
    const { classes, title, searchProps, backButton } = this.props;
    return (
      <div className={classes.root}>
        <AlertInvite
          acceptInvite={this.acceptInvite}
          declineInvite={this.declineInvite}
          inviteDetails={this.state.inviteDetails}
          handleClose={this.handleInviteDialogClose}
          open={this.state.inviteDialogOpen}
        />
        <TopBar
          company_name={this.state.company_name}
          backButton={backButton}
          searchProps={searchProps}
          title={title}
          handleDrawerOpen={this.handleDrawerOpen}
        />
        <nav className={classes.drawer}>
          <Drawer
            container={this.props.container}
            variant="temporary"
            anchor="left"
            transitionDuration={0}
            ModalProps={{
              disableBackdropClick: true,
              BackdropProps: { transitionDuration: 0 }
            }}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerClose}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <Sidebar
              sidebarEnabled={this.state.sidebarEnabled}
              logout={this.logout}
              closeDrawer={this.closeDrawer}
            />
          </Drawer>
        </nav>
        <main className={classes.content}>{this.props.children}</main>
      </div>
    );
  }
}

Layout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(Layout));
