import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import FinancialsTable from './FinancialsTable';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  toolbarStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
});

class Financials extends React.Component {
  state = {};
  renderFinancialsList = isOld => {
    const { classes, load } = this.props;
    const charges = isOld
      ? window._.get(load, 'carrierPortalFields.oldValues.charges')
      : window._.get(load, 'charges', []);

    const totalAmount = isOld
      ? window._.get(load, 'carrierPortalFields.oldValues.totalAmount')
      : load.totalAmount;
    return (
      <List
        style={{ textDecoration: isOld ? 'line-through' : 'none' }}
        subheader={
          <ListSubheader>{`Pay Item(s) Total: $${(+(totalAmount || 0))
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</ListSubheader>
        }
        className={classes.root}
      >
        <Divider />

        {charges.map((row, i) => {
          return (
            <ListItem
              style={{ width: '100%' }}
              divider
              key={row.id}
              role={undefined}
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: 'subtitle1',
                  style: {
                    color: 'black',
                    fontSize: '1em'
                  }
                }}
                primary={
                  <div>
                    <strong>Description</strong>
                    <br />
                    <div>{row.type}</div>
                    {row.external_desc && row.desc ? (
                      <React.Fragment>
                        <strong>Notes</strong>
                        <br />
                        <div>{row.desc}</div>
                      </React.Fragment>
                    ) : null}
                    <strong>Quantity</strong>
                    <br />
                    <div>{row.quantity}</div>
                    <strong>Rate</strong>
                    <br />
                    <div>{row.rate}</div>
                    <strong>Total</strong>
                    <br />
                    <div>{`$${(+(row.total || 0))
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</div>
                  </div>
                }
              />
            </ListItem>
          );
        })}
      </List>
    );
  };
  render() {
    const { classes, load } = this.props;
    if (!load) {
      return null;
    }
    return (
      <div>
        <div style={{ paddingLeft: 8, marginTop: 10 }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => this.props.handleTabChange('New Message')}
          >
            Propose Financial Changes
          </Button>
        </div>
        <Hidden smUp>
          {this.renderFinancialsList()}
          {window._.get(
            load,
            'carrierPortalFields.oldValues.chargesUpdated'
          ) ? (
            <div>
              <h4 style={{ color: 'red', marginLeft: 15, marginRight: 15 }}>
                Financials have been updated by your customer. The previous
                financials are shown below for comparison.
              </h4>
              {this.renderFinancialsList(true)}
            </div>
          ) : null}
        </Hidden>
        <Hidden xsDown>
          <FinancialsTable
            totalAmount={load.totalAmount}
            data={window._.get(load, 'charges', [])}
          />
          {window._.get(
            load,
            'carrierPortalFields.oldValues.chargesUpdated'
          ) ? (
            <div>
              <h4 style={{ color: 'red', marginLeft: 15, marginRight: 15 }}>
                Financials have been updated by your customer. The previous
                financials are shown below for comparison.
              </h4>
              <FinancialsTable
                isOld
                totalAmount={window._.get(
                  load,
                  'carrierPortalFields.oldValues.totalAmount'
                )}
                data={window._.get(
                  load,
                  'carrierPortalFields.oldValues.charges'
                )}
              />
            </div>
          ) : null}
        </Hidden>
      </div>
    );
  }
}

Financials.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Financials);
