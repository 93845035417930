import React from 'react';
import PropTypes from 'prop-types';
import set from 'lodash/set';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Field from '../shared/Field';

const formatDate = inputDate => {
  var date = new Date(inputDate);
  var dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
  return dateString;
};

const toolbarStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  },
  showMobileOnly: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

const fields = [
  {
    label: 'Action Type',
    type: 'static-text',
    path: 'type'
  },
  {
    label: 'Originally Contracted Date/Time',
    type: 'static-text',
    path: 'date',
    render: (value, a) => {
      return `${a.date || 'Date/Time Not Entered'}${
        a.dateEnd ? ' - ' + a.dateEnd : ''
      }`;
    }
  },
  {
    label: 'Scheduled Window Begin',
    type: 'datetime',
    path: 'scheduledWindowBegin'
  },
  {
    label: 'Scheduled Window End',
    type: 'datetime',
    path: 'scheduledWindowEnd'
  }
];

const reasonField = {
  label: 'Reason for change (required)',
  type: 'textarea',
  path: 'reason',
  required: true,
  helperText: 'Please enter a reason for changing the scheduled date/time'
};

const StopDialog = React.memo(props => {
  const { stop, dialogOpen, closeDialog, fullScreen, save, actions } = props;
  if (!stop) {
    return null;
  }

  const [state, setState] = React.useState(cloneDeep(actions));

  function handleDialogClose() {
    setState({});
    closeDialog();
  }
  function handleSave(e) {
    e.preventDefault();

    window.setTimeout(() => {
      save(stop.stopId, state);
      setState({});
      closeDialog();
    }, 100);
  }
  function saveField() {}

  return (
    <Dialog
      disableBackdropClick
      md="lg"
      fullWidth
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {`Schedule your appointment for Stop #${stop.order}`}
        <br />
        <small>{stop.name}</small>
      </DialogTitle>
      <form onSubmit={handleSave}>
        <DialogContent>
          {(stop.actions || []).map(a => {
            return (
              <div key={a.id}>
                {fields.map(f => (
                  <div style={{ marginBottom: 15 }}>
                    <Field
                      {...f}
                      setValue={
                        f.type === 'datetime'
                          ? (value, propName) => {
                              console.log('value, propName', value, propName);
                              set(state, `${a.id}.${f.path}${propName}`, value);
                              setState({ ...state });
                            }
                          : null
                      }
                      save={saveField}
                      defaultValue={
                        f.path === 'scheduledWindowBegin'
                          ? a.date
                            ? formatDate(a.date)
                            : ''
                          : a.dateEnd
                          ? formatDate(a.dateEnd)
                          : ''
                      }
                      dateValue={
                        f.type === 'datetime'
                          ? get(state, `${a.id}.${f.path}Date`, '')
                          : null
                      }
                      timeValue={
                        f.type === 'datetime'
                          ? get(state, `${a.id}.${f.path}Time`, '')
                          : null
                      }
                      value={
                        f.render
                          ? f.render(window._.get(a, f.path, ''), a)
                          : window._.get(a, f.path, '')
                      }
                    />
                  </div>
                ))}
              </div>
            );
          })}
          <Field
            handleOnBlur={() => {}}
            handleOnChange={e => {
              let value = e.target.value;
              set(state, reasonField.path, value);
              setState({ ...state });
            }}
            value={get(state, reasonField.path, '')}
            {...reasonField}
          />
        </DialogContent>
        <DialogActions style={{ paddingBottom: 25 }}>
          <Button onClick={handleDialogClose} variant="contained">
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Save Stop
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

StopDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(toolbarStyles)(StopDialog);
