import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { lighten } from '@material-ui/core/styles/colorManipulator';
import Message from './Message';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const FIELDS = [
  {
    label: 'Date / Time',
    path: 'timestamp',
    render: data => {
      return new Date(data.timestamp).toLocaleString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit', hour12: true, hour: '2-digit',  minute:'2-digit' });
    }
  },
  {
    label: 'Message',
    path: 'message'
  }
];
const FIELDS_TWO = [
  {
    label: 'Line Item Description',
    path: 'lineItemDescription'
  },
  {
    label: 'Amount ($)',
    path: 'amount'
  },
  {
    label: 'Notes',
    path: 'notes'
  }
];
const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  toolbarStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
});

class MessageHistory extends React.Component {
  state = {
    messageHistory: [],
    uploading: false
  };
  renderMessageDialog = fullScreen => {
    const { classes, showDialog, closeDialog } = this.props;
    console.log('showDialog', showDialog);
    return (
      <Message
        typeaheadValues={this.props.typeaheadValues}
        saveMessageApiCall={this.props.saveMessageApiCall}
        fullScreen={fullScreen}
        classes={classes}
        load={this.props.load}
        closeDialog={closeDialog}
        dialogOpen={showDialog}
      />
    );
  };
  render() {
    const { classes, load, openDialog } = this.props;

    return (
      <div>
        <div style={{ paddingLeft: 8, marginTop: 10, marginBottom: 10 }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={openDialog}
          >
            Propose Financial Changes / Send Message
          </Button>
          <Hidden smUp>{this.renderMessageDialog(true)}</Hidden>
          <Hidden xsDown>{this.renderMessageDialog(false)}</Hidden>
        </div>
        <Divider />

        <List
          subheader={
            <ListSubheader>
              {window._.get(load, 'carrierPortalFields.messages', []).length
                ? 'Message History'
                : 'This load has no message history. Click the "Send Message" button above to send a message to your customer.'}
            </ListSubheader>
          }
          className={classes.root}
        >
          <Divider />

          {window._.get(load, 'carrierPortalFields.messages', [])
            .slice()
            .reverse()
            .map((row, i) => {
              return (
                <ListItem
                  style={{ width: '100%' }}
                  divider
                  key={i}
                  role={undefined}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      style: {
                        color: 'black',
                        fontSize: '1em'
                      }
                    }}
                    primary={
                      <div>
                        {FIELDS.map(f => {
                          let value = f.render
                            ? f.render(row)
                            : window._.get(row, f.path, '');
                          return (
                            <React.Fragment>
                              <strong>{f.label}</strong>
                              <br />
                              <div>{value}</div>
                            </React.Fragment>
                          );
                        })}
                        {row.lineItems.length ? (
                          <div>
                            <Typography
                              style={{ fontSize: 18 }}
                              variant="subtitle2"
                            >
                              {row.lineItems.length} Proposed Financial
                              Change(s)
                            </Typography>
                            {row.lineItems.map((l, i) => {
                              return (
                                <React.Fragment>
                                  {FIELDS_TWO.map((f, i2) => {
                                    let value = f.render
                                      ? f.render(l)
                                      : window._.get(l, f.path, '');
                                    return (
                                      <React.Fragment>
                                        <strong>
                                          {true ? (
                                            <strong>{`Change #${i +
                                              1}`}</strong>
                                          ) : null}{' '}
                                          {f.label}
                                        </strong>
                                        <br />
                                        <div>{value}</div>
                                      </React.Fragment>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        ) : null}
                      </div>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </div>
    );
  }
}

MessageHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MessageHistory);
