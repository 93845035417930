import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import { withRouter } from 'react-router-dom';
import AsyncButton from '../shared/AsyncButton';
import Hidden from '@material-ui/core/Hidden';
import { setUser, getUsers } from './api';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from '../layout/Layout';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper';
import green from '@material-ui/core/colors/green';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import set from 'lodash/set';
import get from 'lodash/get';
import Field from '../shared/Field';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const variantIcon = {
  success: CheckCircleIcon
};
const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  success: {
    backgroundColor: green[600]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  toolbarStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  },
  overlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    backgroundColor: 'rgba(255,255,255,0.8)'
  },
  overlayContent: {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#555'
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  },
  showMobileOnly: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

class UsersContainer extends React.Component {
  state = {
    errors: {},
    users: [],
    loading: false,
    saving: false,
    snackbarIsOpen: false
  };
  getUsersApiCall = () => {
    this.setState(
      {
        loading: true
      },
      async () => {
        const users = await getUsers();
        console.log('users', users);
        this.setState({
          users,
          loading: false
        });
      }
    );
  };
  editOrAddUser = (e, user) => {
    this.setState({
      user: user
        ? {
            ...user,
            permissions: { ...(user.permissions || {}) },
            password: '',
            showPassword: false
          }
        : {
            isNewUser: true,
            full_name: '',
            username: '',
            password: '',
            showPassword: false,
            permissions: {}
          }
    });
  };
  getFields = () => {
    const fields = [
      {
        path: 'full_name',
        label: 'Full Name',
        type: 'text',
        required: true
      },
      {
        path: 'username',
        label: 'Email Address (Username)',
        type: this.state.user.isNewUser ? 'text' : 'static-text',
        inputType: 'email',
        error: this.state.errors.username,
        required: true,
        helperText: !this.state.user.isNewUser
          ? 'The username cannot be edited. Instead, add a new user, and, if you wish, disable this one.'
          : ''
      },
      {
        path: 'password',
        label: 'Password',
        type: 'text',
        inputType: this.state.user.showPassword ? 'text' : 'password',
        required: this.state.user.isNewUser ? true : false,
        helperText: !this.state.user.isNewUser
          ? `Only enter a password if you intend to change it. If you change the password, ${
              this.state.user.username
            } will be logged out.`
          : ''
      },
      {
        path: 'showPassword',
        label: 'Show Password',
        type: 'switch'
      }
    ];

    if (!this.state.user.isSelf) {
      fields.push({
        path: 'permissions.canEditUsers',
        label: 'Can Edit Users?',
        type: 'switch'
      });
    }
    return fields;
  };
  componentDidMount() {
    this.getUsersApiCall();
  }
  handleDialogClose = () => {
    this.setState({
      user: null
    });
  };
  disableUser = async () => {
    const result = await setUser(
      'DISABLE_USER',
      this.state.user.username,
      null
    );
    this.setState({
      snackbarIsOpen: `${this.state.user.username} disabled!`,
      user: null
    });
    this.getUsersApiCall();
  };
  enableUser = async () => {
    const result = await setUser('ENABLE_USER', this.state.user.username, null);
    this.setState({
      snackbarIsOpen: `${this.state.user.username} enabled!`,
      user: null
    });
    this.getUsersApiCall();
  };
  handleSave = async event => {
    event.preventDefault();
    this.setState(
      {
        saving: true,
        errors: {}
      },
      async () => {
        try {
          const result = await setUser(
            this.state.user.full_name,
            this.state.user.username,
            this.state.user.password,
            this.state.user.permissions
          );
          console.log('result', result);

          this.setState({
            saving: false
          });
          if (!result.error) {
            this.setState(
              {
                snackbarIsOpen: `${this.state.user.username} saved!`,
                user: null
              },
              () => {
                this.getUsersApiCall();
              }
            );
          } else {
            switch (result.error.errorCode) {
              case 'cancelled':
                return; // another request was started before this one finished
              case 1:
                this.setState({
                  errors: {
                    username:
                      'That email address already has an account. Please use a different email address.'
                  }
                });
                return;
            }
          }
        } catch (err) {
          // this should not happen
          console.log(err);
          this.setState({
            saving: false
          });
        }
      }
    );
  };
  renderForm = fullScreen => {
    return (
      <Dialog
        disableBackdropClick
        md="lg"
        fullWidth
        fullScreen={fullScreen}
        open
        onClose={this.handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {this.state.user.isNewUser
            ? 'Add new user'
            : `Edit ${this.state.user.username}`}
        </DialogTitle>
        {this.state.user.disabled ? (
          <DialogContent>
            <Button
              style={{ marginBottom: 10 }}
              color="primary"
              onClick={this.enableUser}
              variant="contained"
            >
              Enable User
            </Button>
          </DialogContent>
        ) : (
          <form onSubmit={this.handleSave}>
            <DialogContent>
              {this.getFields().map(f => (
                <div style={{ marginBottom: 15 }}>
                  <Field
                    handleOnBlur={() => {}}
                    handleOnChange={e => {
                      let value = e.target.value;
                      if (f.type === 'switch') {
                        value = e.target.checked;
                      }
                      set(this.state.user, f.path, value);
                      this.setState({
                        user: { ...this.state.user }
                      });
                    }}
                    value={get(this, `state.user.${f.path}`, '')}
                    {...f}
                  />
                </div>
              ))}
            </DialogContent>
            <DialogActions style={{ paddingBottom: 25, paddingRight: 17 }}>
              {!this.state.user.isNewUser && !this.state.user.isSelf ? (
                <Button
                  color="secondary"
                  onClick={this.disableUser}
                  variant="contained"
                >
                  Disable User
                </Button>
              ) : null}
              <Button onClick={this.handleDialogClose} variant="contained">
                Cancel
              </Button>
              <AsyncButton
                type="submit"
                variant="contained"
                color="primary"
                loading={this.state.saving}
              >
                {this.state.saving ? 'Saving' : 'Save'}
              </AsyncButton>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  };
  render() {
    const { classes } = this.props;
    const { loading, users } = this.state;

    const Icon = variantIcon['success'];

    return (
      <Layout title="Manage Users">
        <Snackbar
          onClose={() => {
            this.setState({
              snackbarIsOpen: false
            });
          }}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.snackbarIsOpen ? true : false}
        >
          <SnackbarContent
            className={classes.success}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <Icon
                  className={classNames(classes.icon, classes.iconVariant)}
                />
                {this.state.snackbarIsOpen}
              </span>
            }
          />
        </Snackbar>
        <Paper className={classes.root}>
          {loading ? (
            <div className={classes.overlay}>
              <div className={classes.overlayContent}>
                <CircularProgress disableShrink />
              </div>
            </div>
          ) : (
            <React.Fragment>
              {this.state.user ? (
                <React.Fragment>
                  <Hidden smUp>{this.renderForm(true)}</Hidden>
                  <Hidden xsDown>{this.renderForm(false)}</Hidden>
                </React.Fragment>
              ) : null}
              <div style={{ paddingLeft: 8, marginTop: 10 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.editOrAddUser}
                >
                  <AddIcon style={{ marginRight: 10 }} />
                  Add New User
                </Button>
              </div>
              <List
                subheader={<ListSubheader>Users List</ListSubheader>}
                className={classes.root}
              >
                <Divider />

                {users.map((row, i) => {
                  return (
                    <ListItem
                      style={{ width: '100%' }}
                      divider
                      key={row.username}
                      role={undefined}
                      disableRipple
                      button
                      onClick={() => {
                        this.editOrAddUser(null, row);
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'subtitle1',
                          style: {
                            color: 'black',
                            fontSize: '1em'
                          }
                        }}
                        primary={
                          <div>
                            {row.isSelf ? (
                              <i>
                                This is you
                                <br />
                              </i>
                            ) : null}

                            {row.disabled ? (
                              <strong>
                                This user is disabled
                                <br />
                              </strong>
                            ) : null}
                            <strong>Full Name</strong>
                            <br />
                            <div>
                              {row.full_name}
                              <EditIcon
                                style={{ paddingLeft: 5, marginBottom: -3 }}
                                fontSize="small"
                                color="primary"
                              />
                            </div>
                            <strong>Email / Username</strong>
                            <br />
                            <div>{row.username}</div>

                            <strong>Password</strong>
                            <br />
                            <div>
                              <i>*********</i>
                              <EditIcon
                                style={{ paddingLeft: 5, marginBottom: -3 }}
                                fontSize="small"
                                color="primary"
                              />
                            </div>
                            {!row.disabled &&
                            get(row, 'permissions.canEditUsers') ? (
                              <strong>
                                This user can edit users
                                <br />
                              </strong>
                            ) : null}
                          </div>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </React.Fragment>
          )}
        </Paper>
      </Layout>
    );
  }
}

UsersContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(UsersContainer));
