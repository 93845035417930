import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from './account/Login';
import SignUp from './account/SignUp';
import ResetPassword from './account/ResetPassword';
import ForgotPassword from './account/ForgotPassword';
import LoadsContainer from './loads/LoadsContainer';
import LoadContainer from './load/LoadContainer';
import AccountContainer from './account/AccountContainer';
import UsersContainer from './account/UsersContainer';

const AppRoutes = () => {
  return (
    <Switch>
      <Route
        path={'/login'}
        component={() => (
          <AccountContainer>
            <Login />
          </AccountContainer>
        )}
      />
      <Route
        path={'/signup'}
        component={() => (
          <AccountContainer>
            <SignUp />
          </AccountContainer>
        )}
      />
      <Route
        path={'/reset'}
        component={() => (
          <AccountContainer>
            <ResetPassword />
          </AccountContainer>
        )}
      />
      <Route
        path={'/forgot'}
        component={() => (
          <AccountContainer>
            <ForgotPassword />
          </AccountContainer>
        )}
      />
      <Route path={'/loads/:activeTab?'} exact component={LoadsContainer} />
      <Route path={'/users'} exact component={UsersContainer} />
      <Route
        path={'/load/:loadId/:loadNumber/:activeTab?'}
        exact
        component={LoadContainer}
      />
      <Redirect from="/" to="/loads/Tendered Loads" />
    </Switch>
  );
};

export default AppRoutes;
