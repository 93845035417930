import React from 'react';
import Typography from '@material-ui/core/Typography';
import GavelIcon from '@material-ui/icons/Gavel';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';

const FooterLinks = props => (
  <div
    style={{
      paddingLeft: 15,
      paddingRight: 15,
      marginTop: 15,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}
  >
    <Typography variant="caption">
      <a className="valign-center" href="https://ascendtms.com/terms.html">
        <GavelIcon style={{ fontSize: '0.75rem', marginRight: 5 }} /> Terms
        &amp; Conditions
      </a>
    </Typography>
    <Typography variant="caption">
      <a className="valign-center" href="https://ascendtms.com/privacy.html">
        <LockIcon style={{ fontSize: '0.75rem', marginRight: 5 }} /> Privacy
        Policy
      </a>
    </Typography>
    <Typography variant="caption">
      <a
        className="valign-center"
        target="_blank"
        href="mailto:support@inmotionglobal.com"
      >
        <EmailIcon style={{ fontSize: '0.75rem', marginRight: 5 }} /> Email
        Support
      </a>
    </Typography>
  </div>
);

export default FooterLinks;
