import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'calc(100% - 25px)'
    }
  },
  fieldWrapper: {},
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(1) / 4
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const documentTypes = [
  'Carrier Load Confirmation (signed)',
  'POD (Proof Of Delivery)',
  'Carrier Invoice',
  'Claim',
  'Sign In/Out Sheet',
  'Incident Report'
];

function getStyles(documentType, that) {
  return {
    fontWeight:
      that.props.documentType.indexOf(documentType) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium
  };
}

class MultipleSelect extends React.Component {
  render() {
    const { classes, handleChange, documentType } = this.props;

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <Typography
            style={{ minWidth: this.props.minWidth || 250, fontSize: 15 }}
            variant="subtitle2"
          >
            Document Type(s)
          </Typography>
          <div style={{ width: '100%' }}>
            <Select
              fullWidth
              multiple
              value={documentType}
              onChange={handleChange}
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {documentTypes.map(documentType => (
                <MenuItem
                  key={documentType}
                  value={documentType}
                  style={getStyles(documentType, this)}
                >
                  {documentType}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              Please select the type or types that best describe this document.
            </FormHelperText>
          </div>
        </FormControl>
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MultipleSelect);
