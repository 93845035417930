import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    maxWidth: 275
  },
  searchIcon: {
    width: theme.spacing(5),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    width: '100%'
  }
});

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      value: this.props.value,
      active: false,
      showSearch: false
    };
  }
  componentDidMount() {
    window.setTimeout(() => {
      this.setState({
        showSearch: true
      });
    }, 200);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.searchProps.value) {
      this.setState({ ...this.state, value: nextProps.searchProps.value });
    }
  }
  handleKeyUp = e => {
    if (e.charCode === 13 || e.key === 'Enter') {
      this.handleRequestSearch();
    } else if (
      this.props.cancelOnEscape &&
      (e.charCode === 27 || e.key === 'Escape')
    ) {
      this.handleCancel();
    }
    if (this.props.onKeyUp) {
      this.props.onKeyUp(e);
    }
  };
  handleInput = e => {
    this.setState({ value: e.target.value });
    if (this.props.searchProps.onChange) {
      this.props.searchProps.onChange(e.target.value);
    }
  };
  handleRequestSearch = () => {
    if (this.props.searchProps.onRequestSearch) {
      this.props.searchProps.onRequestSearch(this.state.value);
    }
  };
  render() {
    if (!this.state.showSearch) {
      return null;
    }
    const { searchProps, classes } = this.props;
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          autoFocus={false}
          value={this.state.value}
          onChange={this.handleInput}
          // onKeyUp={this.handleKeyUp}
          placeholder={searchProps.placeholder}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SearchBar);
