import React from 'react';
import Typography from '@material-ui/core/Typography';

export default props => (
  <Typography {...props} className="atmslogo">
    <span style={{ fontWeight: 'normal', fontSize: '90%' }}>
      {props.pretext || ''}
    </span>
    <span className="ascend-orange stronger">Ascend</span>
    <span className="ascend-blue">TMS</span>
  </Typography>
);
