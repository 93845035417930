import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import AsyncButton from '../shared/AsyncButton';

function SaveChangesButtons(props) {
  const { saving, isClean, classes, cancelChanges, handleSave } = props;

  return (
    <div style={{ marginBottom: 20, marginLeft: 4 }}>
      {isClean ? null : (
        <Button
          style={{ marginLeft: 14 }}
          onClick={cancelChanges}
          variant="contained"
        >
          Cancel Changes
        </Button>
      )}
      <AsyncButton
        loading={saving}
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={saving || isClean}
        onClick={handleSave}
      >
        {isClean ? 'No changes to save' : 'Save Changes'}
        <SaveIcon className={classes.rightIcon} />
      </AsyncButton>
    </div>
  );
}

export default SaveChangesButtons;
