import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class AccountContainer extends Component {
  componentDidMount() {
    // redirect if user is already logged in
    if (window.localStorage.getItem('authtoken')) {
      this.props.history.push('/');
    }
  }
  render() {
    return this.props.children;
  }
}

export default withRouter(AccountContainer);
