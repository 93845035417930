import get from 'lodash/get';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import queryString from 'query-string';
import saveError from './saveError';

window._ = { get };

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: orange
  },
  drawerWidth: 240,
  spacing: value => value * 8,
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xs: 0,
      sm: 820,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
});

const { code } = queryString.parse(window.location.search);

if (code) {
  window.localStorage.setItem('code', code);
}

class App extends React.PureComponent {
  state = {};
  componentDidMount() {}
  render() {
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <AppRoutes />
        </MuiThemeProvider>
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
