import { API_ENDPOINT } from '../config';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import get from 'lodash/get';
import { getCancelError, getAxiosOptions } from '../utility';
axiosCancel(axios);

export const uploadFile = async (
  file,
  documentType,
  loadId,
  description = ''
) => {
  console.log('description', description);

  console.log('file', file);
  const endpoint = 'app/uploadfile';
  const formData = new FormData();
  formData.append('file', file);
  formData.append('documentType', documentType);
  formData.append('description', description);
  formData.append('loadId', loadId);
  formData.append('userFullName', window.localStorage.getItem('userFullName'));
  try {
    const response = await axios.post(`${API_ENDPOINT}/${endpoint}`, formData, {
      ...getAxiosOptions(endpoint)
    });
    return response.data;
  } catch (err) {
    console.log('err', err);
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const getAttachment = async (attachmentDocId, filename) => {
  const endpoint = 'app/getattachment';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        attachmentDocId,
        filename
      },
      { ...getAxiosOptions(endpoint), responseType: 'blob' }
    );

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/pdf' })
    );

    return url;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const getTypeaheadValues = async () => {
  const endpoint = 'app/getTypeaheadValues';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {},
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const saveCheckCall = async (loadNumber, checkCall) => {
  checkCall.updatedBy = window.localStorage.getItem('userFullName');
  const endpoint = 'app/saveCheckCall';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadNumber,
        checkCall
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const saveMessage = async (loadNumber, message) => {
  message.messageBy = window.localStorage.getItem('userFullName');
  const endpoint = 'app/savemessage';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadNumber,
        message
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const handleStopReorder = async (loadNumber, stopOrder) => {
  const endpoint = 'app/reorderstops';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadNumber,
        stopOrder
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const getLoad = async loadId => {
  const endpoint = 'app/getload';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadId
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const getDriverProfile = async (driverName, loadNumber) => {
  const endpoint = 'app/getdriverprofile';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        driverName,
        loadNumber
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const editStop = async (loadNumber, stopId, payload) => {
  console.log('loadNumber, stopId, payload', loadNumber, stopId, payload);
  const endpoint = 'app/editstop';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadNumber,
        stopId,
        payload
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const saveLoadFields = async (loadNumber, updatesToSave) => {
  const endpoint = 'app/saveloadfields';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        loadNumber,
        updatesToSave
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const getCustomerInfo = async customerOrgId => {
  const endpoint = 'app/getcustomerinfo';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        customerOrgId
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};
