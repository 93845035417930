const ASSET_HELPER_TEXT =
  'Your customer requires that you enter this info, if applicable.';

export const assetInfo = [
  {
    minWidth: 150,
    groupTitle: 'Equipment Specs',
    fields: [
      {
        path: 'truckType',
        label: 'Equipment Type',
        type: 'static-text',
        defaultValue: 'Not Set'
      },
      {
        path: 'truckLength',
        label: 'Equipment Length',
        type: 'static-text',
        defaultValue: 'Not Set'
      },
      {
        path: 'weight',
        label: 'Weight',
        type: 'static-text',
        defaultValue: 'Not Set'
      },
      {
        label: 'Load Size',
        type: 'static-text',
        defaultValue: 'Not Set',
        path: 'load_size'
      },
      {
        path: 'commodity',
        label: 'Commodity',
        type: 'static-text',
        defaultValue: 'Not Set'
      },
      {
        path: 'temperature',
        label: 'Temperature',
        type: 'static-text',
        defaultValue: 'Not Set'
      }
    ]
  },
  {
    minWidth: 150,
    groupTitle: 'Driver and Equipment Information',
    fields: [
      {
        path: 'carrierPortalFields.driver_name',
        type: 'typeahead',
        label: 'Driver #1 Name',
        typeAheadOn: 'driverName',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      },
      {
        path: 'carrierPortalFields.driver_phone',
        type: 'typeahead',
        label: 'Driver #1 Phone',
        typeAheadOn: 'driverPhone',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      },
      {
        path: 'carrierPortalFields.driver_email',
        type: 'typeahead',
        label: 'Driver #1 Email',
        typeAheadOn: 'driverEmail',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      },

      {
        path: 'carrierPortalFields.driver_2_name',
        type: 'typeahead',
        label: 'Driver #2 Name',
        typeAheadOn: 'driverName',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      },
      {
        path: 'carrierPortalFields.driver_2_phone',
        type: 'typeahead',
        label: 'Driver #2 Phone',
        typeAheadOn: 'driverPhone',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      },
      {
        path: 'carrierPortalFields.driver_2_email',
        type: 'typeahead',
        label: 'Driver #2 Email',
        typeAheadOn: 'driverEmail',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      },
      {
        path: 'carrierPortalFields.power_unit',
        type: 'typeahead',
        label: 'Power Unit',
        typeAheadOn: 'powerUnit',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      },
      {
        path: 'carrierPortalFields.trailer',
        type: 'typeahead',
        label: 'Trailer',
        typeAheadOn: 'trailer',
        helperText: ASSET_HELPER_TEXT,
        hideHelperWhenDirty: true
      }
    ]
  }
];

export const loadBasics = [
  {
    minWidth: 250,
    groupTitle: 'Status / Ref # / Assigned User',
    fields: [
      {
        label: 'Portal Status',
        type: 'static-text',
        path: 'carrierPortalFields.loadStatus'
      },
      {
        path: 'carrierPortalFields.lastCheckCall.statusDescription',
        label: 'Latest Truck Status',
        type: 'static-text',
        defaultValue: 'Not Set'
      },
      {
        path: 'carrierPortalFields.reference',
        label: 'Load Reference ID / Numbers',
        type: 'textarea',
        helperText: 'enter your own load id or reference numbers (optional)'
      },
      {
        path: 'carrierPortalFields.assignedUser',
        label: 'Assign Team Member to Load',
        helperText:
          'enter a team member/dispatcher to be able to filter loads by name (optional)',
        type: 'typeahead',
        typeAheadOn: 'assignedUser'
      }
    ]
  },

  {
    minWidth: 150,
    groupTitle: 'Customer Information',
    fields: [
      {
        label: 'Customer',
        type: 'static-text',
        path: 'name'
      },
      {
        label: 'Address',
        type: 'static-text',
        path: 'name',
        render: (value, row) => {
          if (!row) {
            return null;
          }
          const toReturn = [];
          if (row.address1) {
            toReturn.push(row.address1);
          }
          if (row.address2) {
            toReturn.push(row.address2);
          }
          if (row.city || row.state) {
            toReturn.push(`${row.city || ''}, ${row.state || ''}`);
          }
          return toReturn.join('\n');
        }
      },
      {
        label: 'Phone #',
        type: 'static-text',
        path: 'phone'
      },
      {
        label: 'Fax #',
        type: 'static-text',
        path: 'fax'
      },
      {
        label: 'Docket Number',
        type: 'static-text',
        path: 'mcNum'
      },
      {
        label: 'USDOT Number',
        type: 'static-text',
        path: 'usdotNum'
      },
      {
        label: 'SCAC',
        type: 'static-text',
        path: 'scacCode'
      }
    ]
  },
  {
    groupTitle: 'Load Notes',
    fields: [
      {
        path: 'notes',
        label: "Your Customer's Notes",
        type: 'static-text',
        defaultValue: 'Not Set'
      },
      {
        path: 'carrierPortalFields.privateLoadNotes',
        label: 'Your Private Notes',
        type: 'textarea',
        helperText:
          'enter private load notes only viewable by your organization'
      }
    ]
  }
];
