import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import LongPress from 'react-long';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  secondaryAction: {
    marginBottom: 30
  },
  toolbarStyles: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  }
});

class AppList extends React.Component {
  handleButtonPress = row => {
    this.buttonPressTimer = setTimeout(() => {
      this.isLongPress = true;
      if (this.props.selected.includes(row.id)) {
        // uncheck
        this.props.selectRow({ target: { checked: false } }, row.id);
      } else {
        // check it
        this.props.selectRow({ target: { checked: true } }, row.id);
      }
    }, 1000);
  };
  handleLongPress = row => {
    if (this.props.selected.includes(row.id)) {
      // uncheck
      this.props.selectRow({ target: { checked: false } }, row.id);
    } else {
      // check it
      this.props.selectRow({ target: { checked: true } }, row.id);
    }
  };
  handlePress = row => {
    if (!this.props.isSelectMode) {
      this.props.handleClick(row);
    } else {
      this.handleLongPress(row);
    }
  };
  handleButtonRelease = row => {
    console.log();
    clearTimeout(this.buttonPressTimer);
    var isLongPress = this.isLongPress;
    this.isLongPress = false;
    if (isLongPress) {
      return;
    }
    if (!this.props.isSelectMode) {
      this.props.handleClick(row);
    } else if (this.props.selected.includes(row.id)) {
      // uncheck
      this.props.selectRow({ target: { checked: false } }, row.id);
    } else {
      // check it
      this.props.selectRow({ target: { checked: true } }, row.id);
    }
  };
  render() {
    const { columns, classes, rows, selected, activeTab } = this.props;

    return (
      <div>
        <Toolbar className={classes.toolbarStyles}>
          <Typography variant="subtitle1">
            Press and hold to select load(s) and perform load actions
          </Typography>
        </Toolbar>
        <List className={classes.root}>
          {rows.map(row => {
            const isSelected = selected.includes(row.id);
            return (
              <LongPress
                style={{}}
                key={row.id}
                time={800}
                onLongPress={() => this.handleLongPress(row)}
                onPress={() => this.handlePress(row)}
              >
                <ListItem
                  key={row.id}
                  style={{
                    width: '100%',
                    backgroundColor: isSelected ? '#FDF2E4' : 'inherit'
                  }}
                  divider
                  role={undefined}
                  button
                >
                  <div style={{ width: '100%' }}>
                    {isSelected ? (
                      <ListItemText
                        primary="Selected"
                        primaryTypographyProps={{
                          style: { color: 'rgb(255, 145, 0)' }
                        }}
                      />
                    ) : this.props.isSelectMode ? (
                      <ListItemText primary="Tap to select" />
                    ) : null}
                    {columns.map(c =>
                      c.dontShowOn &&
                      c.dontShowOn.includes(activeTab) ? null : (
                        <div key={c.key} style={{ width: '100%' }}>
                          <ListItemText
                            style={{
                              width: '100%',
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                            primary={
                              c.key === 'stops' && row.stops ? '' : c.title
                            }
                            secondaryTypographyProps={{
                              variant: 'subtitle1',
                              style: { color: 'black', fontSize: '.95em' }
                            }}
                            primaryTypographyProps={{
                              variant: 'subtitle1',
                              style: { color: 'grey', fontSize: '.9em' }
                            }}
                            secondary={
                              c.render
                                ? c.render(window._.get(row, c.key), row)
                                : window._.get(row, c.dataIndex, <i>Not Set</i>)
                            }
                          />
                        </div>
                      )
                    )}
                  </div>
                </ListItem>
              </LongPress>
            );
          })}
        </List>
      </div>
    );
  }
}

AppList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AppList);
