import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import tabDefinition from './tabDefinition';
import LoadTabMenu from './LoadTabMenu';
import Hidden from '@material-ui/core/Hidden';

const styles = {
  root: {
    flexGrow: 1
  }
};

class CenteredTabs extends React.Component {
  handleChange = (e, value) => {
    this.props.handleTabChange(tabDefinition[value]);
  };
  render() {
    return (
      <React.Fragment>
        <Hidden smUp>
          <LoadTabMenu
            loadActionsProps={this.props.loadActionsProps}
            value={
              this.props.activeTab
                ? tabDefinition.indexOf(this.props.activeTab)
                : tabDefinition.indexOf('Status, Customer, Ref No.')
            }
            onChange={this.handleChange}
            tabDefinition={tabDefinition}
          />
        </Hidden>
        <Hidden xsDown>
          <Tabs
            style={{
              backgroundColor: '#1D8BF1'
            }}
            value={
              this.props.activeTab
                ? tabDefinition.indexOf(this.props.activeTab)
                : tabDefinition.indexOf('Status, Customer, Ref No.')
            }
            onChange={this.handleChange}
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="on"
          >
            {tabDefinition.map(d => (
              <Tab style={{ color: 'white' }} key={d} label={d} />
            ))}
          </Tabs>
        </Hidden>
      </React.Fragment>
    );
  }
}

CenteredTabs.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CenteredTabs);
