import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import set from 'lodash/set';
import get from 'lodash/get';
import Field from '../shared/Field';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const toolbarStyles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    },
    color: theme.palette.secondary.main,
    backgroundColor: lighten(theme.palette.secondary.light, 0.85)
  },
  showMobileOnly: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

const Message = React.memo(props => {
  if (!props.load || !props.load.stops) {
    return null;
  }
  const { dialogOpen, closeDialog, fullScreen, load } = props;

  const [state, setState] = React.useState({ lineItems: [] });

  const fieldsOne = [
    {
      label: 'Message',
      type: 'textarea',
      placeholder: '',
      path: 'message',
      value: state.message || '',
      handleOnBlur: () => {},
      handleOnChange: e => {
        state.message = e.target.value;
        setState({ ...state });
      }
    }
  ];
  const fieldsTwo = [
    {
      label: 'Line Item Description',
      type: 'typeahead',
      typeAheadOn: 'lineItemDescription',
      placeholder: 'select a line item or type a description',
      path: 'lineItemDescription'
    },
    {
      label: 'Amount ($)',
      inputType: 'number',
      type: 'text',
      placeholder: '',
      path: 'amount'
    },
    {
      label: 'Notes',
      type: 'textarea',
      placeholder: '',
      path: 'notes'
    }
  ];

  function handleDialogClose() {
    setState({});
    closeDialog();
  }
  function handleSave() {
    props.saveMessageApiCall({ ...state });
    setState({});
    closeDialog();
  }

  function addLineItem() {
    state.lineItems.push({
      description: '',
      amount: '',
      notes: ''
    });
    setState({ ...state });
  }
  function removeLineItem() {
    state.lineItems.pop();
    setState({ ...state });
  }

  useEffect(() => {
    if (
      window.location.search &&
      window.location.search === '?showFinancialChanges=true'
    ) {
      addLineItem();
    }
  }, []);

  return (
    <Dialog
      disableBackdropClick
      md="lg"
      fullWidth
      fullScreen={fullScreen}
      open={dialogOpen}
      onClose={handleDialogClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Send message to your customer
      </DialogTitle>
      <DialogContent>
        <div>
          {fieldsOne.map(f => (
            <div>
              <Field {...f} />
            </div>
          ))}
        </div>
        <br />
        <Typography variant="subtitle2">
          Propose Financial Changes (optional)
        </Typography>
        <div>
          {state.lineItems.map((l, i) => {
            return (
              <div key={i} style={{ marginTop: 10 }}>
                {i > 0 ? (
                  <hr style={{ marginTop: 15, marginBottom: 15 }} />
                ) : null}
                {fieldsTwo.map(f => (
                  <div style={{ marginBottom: 15 }} key={f.path}>
                    <Field
                      typeaheadValues={
                        f.typeAheadOn
                          ? props.typeaheadValues &&
                            props.typeaheadValues[f.typeAheadOn]
                            ? props.typeaheadValues[f.typeAheadOn]
                            : []
                          : null
                      }
                      {...f}
                      value={state.lineItems[i][f.path]}
                      handleOnChange={e => {
                        let value = e.target.value;
                        state.lineItems[i][f.path] = value;
                        setState({ ...state });
                      }}
                      handleOnBlur={() => {}}
                    />
                  </div>
                ))}
              </div>
            );
          })}
        </div>
        <ButtonGroup style={{ marginTop: 10 }} variant="contained">
          <Button onClick={addLineItem}>+ Add Line Item</Button>
          {state.lineItems.length ? (
            <Button onClick={removeLineItem}>- Remove Line Item</Button>
          ) : null}
        </ButtonGroup>
      </DialogContent>
      <DialogActions style={{ paddingBottom: 25 }}>
        <Button onClick={handleDialogClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
});

Message.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(toolbarStyles)(Message);
