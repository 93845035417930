import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { lighten } from '@material-ui/core/styles/colorManipulator';
import CheckCall from './CheckCall';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';

const FIELDS = [
  {
    label: 'Status Description',
    path: 'statusDescription'
  },
  {
    label: 'Stop Location',
    path: 'stopLocation'
  },
  {
    label: 'Current Location',
    path: 'currentLocation'
  },
  {
    label: 'Details or Notes',
    path: 'notes'
  },
  {
    label: 'Date / Time',
    path: 'dateTime',
    render: data => {
      return `${data.Date} ${data.Time}`;
    }
  }
];

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  },
  toolbarStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85)
  },
  button: {
    margin: theme.spacing(1)
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20
  }
});

class CheckCallHistory extends React.Component {
  state = {
    checkCallHistory: [],
    uploading: false
  };
  componentDidMount() {
    if (this.props.showStatusWarning) {
      this.props.showStatusWarning();
    }
  }

  renderCheckCallDialog = fullScreen => {
    const { classes, showUpdateTruckStatus, closeDialog } = this.props;
    console.log('showUpdateTruckStatus', showUpdateTruckStatus);
    return (
      <CheckCall
        saveCheckCallApiCall={this.props.saveCheckCallApiCall}
        fullScreen={fullScreen}
        classes={classes}
        load={this.props.load}
        closeDialog={closeDialog}
        dialogOpen={showUpdateTruckStatus}
      />
    );
  };
  render() {
    const { classes, load, openDialog } = this.props;

    return (
      <div>
        <div style={{ paddingLeft: 8, marginTop: 10, marginBottom: 10 }}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={openDialog}
          >
            Update your truck status
          </Button>
          <Hidden smUp>{this.renderCheckCallDialog(true)}</Hidden>
          <Hidden xsDown>{this.renderCheckCallDialog(false)}</Hidden>
        </div>
        <Divider />
        <List
          subheader={
            <ListSubheader>
              {window._.get(load, 'carrierPortalFields.checkCallHistory', [])
                .length
                ? 'Truck Status Log'
                : 'This load has no truck status updates. Click the "Update Your Truck Status" button above to update the load\'s status.'}
            </ListSubheader>
          }
          className={classes.root}
        >
          <Divider />

          {window._.get(load, 'carrierPortalFields.checkCallHistory', [])
            .slice()
            .reverse()
            .map((row, i) => {
              return (
                <ListItem
                  style={{ width: '100%' }}
                  divider
                  key={i}
                  role={undefined}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      variant: 'subtitle1',
                      style: {
                        color: 'black',
                        fontSize: '1em'
                      }
                    }}
                    primary={
                      <div>
                        {FIELDS.map(f => {
                          let value = f.render
                            ? f.render(row)
                            : window._.get(row, f.path, '');
                          return (
                            <React.Fragment>
                              <strong>{f.label}</strong>
                              <br />
                              <div>{value}</div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </div>
    );
  }
}

CheckCallHistory.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CheckCallHistory);
