import get from "lodash/get";
import reduce from "lodash/reduce";
import each from "lodash/each";
import isNil from "lodash/isNil";
import React from "react";
import { formatDate } from "../utility";

export const loadColumns = [
  {
    title: "Your Customer",
    className: "no-wrap-column",
    dataIndex: "customerName",
    key: "customerName",
    size: 1.25,
  },
  {
    title: "Last Updated",
    className: "no-wrap-column",
    dataIndex: "lastUpdated",
    key: "lastUpdated",
    size: 1,
    render: (value, row) => {
      if (value) {
        return new Date(value).toLocaleString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return null;
    },
  },
  {
    title: "Customer Reference#",
    className: "no-wrap-column",
    dataIndex: "customerReferenceNumber",
    key: "customerReferenceNumber",
    size: 1,
  },
  {
    title: "Portal Status",
    className: "no-wrap-column",
    dataIndex: "loadStatus",
    key: "loadStatus",
    size: 1,
    render: (value, row) => {
      if (value === "Archived" && get(row, "prevLoadStatus")) {
        return `${value} (previous status: ${get(row, "prevLoadStatus")})`;
      }
      return value;
    },
  },
  {
    title: "Customer Load Status",
    className: "no-wrap-column",
    dataIndex: "ascendLoadStatus",
    key: "ascendLoadStatus",
    size: 1,
  },
  {
    title: "Customer Truck Status",
    className: "no-wrap-column",
    dataIndex: "ascendTruckStatus",
    key: "ascendTruckStatus",
    size: 1,
  },
  {
    title: "Total Amount",
    className: "no-wrap-column",
    dataIndex: "totalAmount",
    key: "totalAmount",
    size: 1,
    render: (value) => {
      return `$${(+(value || 0))
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
  },
  {
    title: "Expiration",
    className: "no-wrap-column",
    dataIndex: "tenderExpiresOn",
    key: "tenderExpiresOn",
    size: 1,
    render: (value, row) => {
      if (value) {
        return new Date(value).toLocaleString("en-US", {
          year: "2-digit",
          month: "2-digit",
          day: "2-digit",
          hour12: true,
          hour: "2-digit",
          minute: "2-digit",
        });
      }
      return null;
    },
    dontShowOn: ["Active Loads"],
  },
  {
    title: "Load Reference#",
    className: "no-wrap-column",
    dataIndex: "reference",
    key: "reference",
    size: 1,
  },
  {
    title: "Equipment Requested",
    className: "no-wrap-column",
    dataIndex: "equipment",
    key: "equipment",
    size: 1,
  },
  {
    title: "Weight",
    className: "no-wrap-column",
    dataIndex: "weight",
    key: "weight",
    size: 1,
  },
  {
    title: "Stops",
    className: "stops-column",
    dataIndex: "stops",
    key: "stops",
    size: 1.5,
    render: (stops, row) => {
      if (!get(stops, "stopOrder")) {
        return <em>Not set</em>;
      }
      return (
        <React.Fragment>
          {reduce(
            get(stops, "stopOrder", []),
            (final, id, index) => {
              const stop = stops[id];
              if (stop && stop.actions) {
                var nameToDisplay = stop.name;
                if (stop.block_address) {
                  var arr_address, address_line;
                  arr_address = stop.block_address.split(/\n/);
                  do {
                    address_line = arr_address.pop();
                  } while (
                    address_line === "United States" ||
                    address_line === "USA" ||
                    address_line.replace(/^\s+|\s+$/g, "") === ""
                  );
                  address_line = address_line.replace(
                    /\s+((\d{5}(-\d{4})?)|(\w\d\w\s?\d\w\d))\s*$/g,
                    ""
                  );
                  nameToDisplay += " - " + address_line;
                }

                each(stop.actions, (action, actionIndex) => {
                  var beginDateDisplay = action.date ? (
                    action.date
                  ) : (
                    <em>Not set</em>
                  );
                  var endDateDisplay = action.dateEnd
                    ? " - " + action.dateEnd
                    : "";
                  final.push(
                    <div key={`${id}${actionIndex}`} className="stops-item-div">
                      <div>
                        <strong className="order-float-left">
                          {`${index + 1}.`}&nbsp;
                        </strong>
                        {`${action.type}: ${nameToDisplay}`}
                      </div>
                      <div style={{}}>
                        {row.actions && row.actions[action.id] ? (
                          <div>
                            {`${formatDate(
                              get(
                                row.actions,
                                `${action.id}.scheduledWindowBeginDate`
                              )
                            )} ${
                              get(
                                row.actions,
                                `${action.id}.scheduledWindowBeginTime`
                              ) || ""
                            }${
                              get(
                                row.actions,
                                `${action.id}.scheduledWindowEndDate`
                              )
                                ? " - "
                                : ""
                            }`}{" "}
                            {get(
                              row.actions,
                              `${action.id}.scheduledWindowEndDate`
                            ) ? (
                              <div>
                                {`${formatDate(
                                  get(
                                    row.actions,
                                    `${action.id}.scheduledWindowEndDate`
                                  )
                                )} ${
                                  get(
                                    row.actions,
                                    `${action.id}.scheduledWindowEndTime`
                                  ) || ""
                                }`}
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div>
                            {beginDateDisplay}
                            {endDateDisplay}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                  if (
                    actionIndex === stop.actions.length - 1 &&
                    index === stops.stopOrder.length - 1
                  ) {
                    return;
                  } else {
                    final.push(<hr key={`${id}hr`} className="subtlehr" />);
                  }
                });
              }
              return final;
            },
            []
          )}
        </React.Fragment>
      );
    },
  },
];

// set column widths
let totalColumns = 0;
each(loadColumns, (c) => {
  totalColumns += c.size;
});
let sizePerColumn = 100 / totalColumns;
each(loadColumns, (c) => {
  c.columnStyle = { width: `${c.size * sizePerColumn}%` };
});
