import { API_ENDPOINT } from '../config';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import get from 'lodash/get';
import { getCancelError, getAxiosOptions, saveResponse } from '../utility';
axiosCancel(axios);

export const getUsers = async () => {
  const endpoint = 'app/getusers';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {},
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const setUser = async (full_name, username, password, permissions) => {
  console.log('full_name, username, password', full_name, username, password);
  const endpoint = 'app/setuser';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        full_name,
        username,
        password,
        permissions
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const acceptLink = async inviteCode => {
  const endpoint = 'app/acceptlink';
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        inviteCode: inviteCode.replace(/"/g, '')
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const getInviteDetails = async inviteCode => {
  try {
    const endpoint = 'getinvitedetails';
    const response = await axios.post(
      `${API_ENDPOINT}/${endpoint}`,
      {
        inviteCode: inviteCode.replace(/"/g, '')
      },
      getAxiosOptions(endpoint)
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const resetPasswordApiCall = async (password, valtoken) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/auth/resetpassword`,
      {
        password,
        valtoken
      },
      {
        requestId: `/auth/resetpassword`
      }
    );
    saveResponse(response.data);
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const forgotPasswordApiCall = async username => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/auth/forgotpassword`,
      {
        username
      },
      {
        requestId: `/auth/forgotpassword`
      }
    );
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const loginApiCall = async (username, password) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/auth/login`,
      {
        username,
        password
      },
      {
        requestId: `/auth/login`
      }
    );
    saveResponse(response.data);
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};

export const signupApiCall = async (
  full_name,
  username,
  password,
  company_name
) => {
  try {
    const response = await axios.post(
      `${API_ENDPOINT}/auth/register`,
      {
        full_name,
        username,
        password,
        signupApiCall,
        company_name
      },
      {
        requestId: `/auth/register`
      }
    );
    saveResponse(response.data);
    return response.data;
  } catch (err) {
    const cancelError = getCancelError(err);
    if (cancelError) {
      return cancelError;
    }
    if (get(err, 'response.data')) {
      return {
        error: get(err, 'response.data')
      };
    }
    throw err;
  }
};
