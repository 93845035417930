import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LogoTypography from '../shared/LogoTypography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { withRouter } from 'react-router-dom';
import tabDefinition from '../loads/tabDefinition';

const styles = theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: theme.drawerWidth,
      flexShrink: 0
    }
  },
  disableTransition: {
    transition: 'none'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    width: theme.drawerWidth
  }
});

const goToHelp = () => {
  window.open('https://ascendtms.kayako.com/article/111-ascendportal-for-carriers');
};

const Sidebar = props => {
  const { classes, closeDrawer, logout } = props;
  const canEditUsers = window.localStorage.getItem('canEditUsers');
  return (
    <div>
      <div className={classes.toolbar}>
        <LogoTypography component="span" variant="h6" />
      </div>
      <Divider />
      {tabDefinition.map(d => {
        return (
          <ListItem
            disabled={!props.sidebarEnabled}
            disableRipple
            button
            onClick={() => {
              closeDrawer();
              props.history.push(`/loads/${d}`);
            }}
          >
            <ListItemText primary={d} />
          </ListItem>
        );
      })}
      {canEditUsers === 'true' ? (
        <React.Fragment>
          <Divider />
          <ListItem
            disableRipple
            button
            onClick={() => {
              closeDrawer();
              props.history.push(`/users`);
            }}
          >
            <ListItemText primary="Manage Users" />
          </ListItem>
        </React.Fragment>
      ) : null}
      <ListItem disableRipple button onClick={goToHelp}>
        <ListItemText primary="Get Help / Learn" />
      </ListItem>
      <Divider />
      <ListItem disableRipple button onClick={closeDrawer}>
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText primary="Close Menu" />
      </ListItem>
      <Divider />

      <ListItem disableRipple button onClick={logout}>
        <ListItemIcon>
          <ExitIcon />
        </ListItemIcon>
        <ListItemText primary="Log Out" />
      </ListItem>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(Sidebar));
