import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withRouter } from 'react-router-dom';
import LogoTypography from '../shared/LogoTypography';
import ATMSLogoTypography from '../shared/ATMSLogoTypography';
import AsyncButton from '../shared/AsyncButton';
import { signupApiCall, getInviteDetails } from './api';
import FooterLinks from './FooterLinks';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    marginTop: theme.spacing(3)
  },
  forgotPassword: {
    textAlign: 'right'
  }
});

class SignUp extends Component {
  state = {
    loading: false,
    full_name: '',
    username: '',
    password: '',
    passwordConfirm: '',
    errors: {}
  };
  async componentDidMount() {
    this.mounted = true;
    this.inviteCode = window.localStorage.getItem('code');
    if (this.inviteCode) {
      const inviteDetails = await getInviteDetails(this.inviteCode);
      if (inviteDetails) {
        this.setState({ inviteDetails });
      }
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  handleSignup = event => {
    console.log('handleSignup');
    event.preventDefault();
    const {
      full_name,
      username,
      password,
      passwordConfirm,
      company_name
    } = this.state;
    if (password !== passwordConfirm) {
      this.setState({
        errors: {
          password: 'Your passwords do not match.',
          passwordConfirm: 'Your passwords do not match.'
        }
      });
      return;
    }
    this.setState(
      {
        loading: true,
        errors: {}
      },
      async () => {
        try {
          const result = await signupApiCall(
            full_name,
            username,
            password,
            company_name
          );
          console.log('signupApiCall result', result);
          if (!this.mounted) {
            return;
          }
          this.setState({
            loading: false
          });
          if (!result.error) {
            // success!
            this.props.history.push('/');
          } else {
            switch (result.error.errorCode) {
              case 'cancelled':
                return; // another request was started before this one finished
              case 1:
                this.setState({
                  errors: {
                    username:
                      'That email address already has an account. Please log in or use a different email address.'
                  }
                });
                return;
            }
          }
        } catch (err) {
          // this should not happen
          console.log(err);
          this.setState({
            loading: false
          });
        }
      }
    );
  };
  handleFieldChange = name => event => {
    this.state.errors[name] = null;
    this.setState({
      [name]: event.target.value
    });
  };
  render() {
    const { classes, history } = this.props;
    const { loading } = this.state;

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography style={{ fontSize: '1rem' }} component="h1" variant="h5">
            {window._.get(this.state, 'inviteDetails.customerName', '')
              ? `You’ve been invited by ${window._.get(
                  this.state,
                  'inviteDetails.customerName',
                  ''
                )} to create your account with`
              : 'Create account for'}
          </Typography>
          <div>
            <LogoTypography component="h1" variant="h4" />
            <div style={{ float: 'right' }}>
              <ATMSLogoTypography
                style={{ fontSize: '1.1rem' }}
                pretext="powered by "
                component="h1"
                variant="h5"
              />
            </div>
          </div>
          <div style={{ margin: 15, color: 'grey' }}>
            *This is a one-time process for connecting to all AscendTMS shippers
            and brokers to electronically accept load tenders and provide
            updates. Accepting future invites from other customers will use this
            same account.
          </div>
          <form className={classes.form} onSubmit={this.handleSignup}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="name">Your Name</InputLabel>
              <Input
                value={this.state.full_name}
                onChange={this.handleFieldChange('full_name')}
                id="name"
                name="name"
                autoComplete="name"
                autoFocus
              />
              <FormHelperText>Please provide your full name.</FormHelperText>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="name">Your Company Name</InputLabel>
              <Input
                value={this.state.company_name}
                onChange={this.handleFieldChange('company_name')}
                id="company_name"
                name="company_name"
                autoComplete="company_name"
              />
              <FormHelperText>
                Please provide your company's name.
              </FormHelperText>
            </FormControl>
            <FormControl
              error={!!this.state.errors.username}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input
                value={this.state.username}
                onChange={this.handleFieldChange('username')}
                type="email"
                id="email"
                name="email"
                autoComplete="email"
              />
              {this.state.errors.username ? (
                <FormHelperText>{this.state.errors.username}</FormHelperText>
              ) : (
                <FormHelperText>
                  Your email address will be used as your username.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              error={!!this.state.errors.password}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input
                value={this.state.password}
                onChange={this.handleFieldChange('password')}
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {this.state.errors.password ? (
                <FormHelperText>{this.state.errors.password}</FormHelperText>
              ) : (
                <FormHelperText>
                  We do not enforce any password rules, but try to use a strong
                  password that you will remember.
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              error={!!this.state.errors.passwordConfirm}
              margin="normal"
              required
              fullWidth
            >
              <InputLabel htmlFor="password">Confirm Password</InputLabel>
              <Input
                value={this.state.passwordConfirm}
                onChange={this.handleFieldChange('passwordConfirm')}
                name="passwordConfirm"
                type="password"
                id="passwordConfirm"
                autoComplete="current-password"
              />
              {this.state.errors.passwordConfirm ? (
                <FormHelperText>
                  {this.state.errors.passwordConfirm}
                </FormHelperText>
              ) : (
                <FormHelperText>
                  Enter the same password again to ensure you did not type it in
                  incorrectly.
                </FormHelperText>
              )}
            </FormControl>
            <AsyncButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={loading}
            >
              {loading ? 'Launching' : 'Launch AscendPortal'}
            </AsyncButton>
            <Button
              onClick={() => {
                history.push(`/login`);
              }}
              fullWidth
              variant="outlined"
              color="secondary"
              className={classes.submit}
            >
              Use existing Account
            </Button>
          </form>
        </Paper>
        <FooterLinks />

        <div style={{ marginTop: 15, marginBottom: 25 }}>
          Learn more about how you can use{' '}
          <LogoTypography component="span" variant="span" /> to receive load
          tenders, provide load updates, and process your paperwork faster by
          clicking{' '}
          <a
            href="https://ascendtms.kayako.com/article/111-ascendportal-for-carriers"
            target="_blank"
          >
            here
          </a>
          .
        </div>
      </main>
    );
  }
}

export default withStyles(styles)(withRouter(SignUp));
